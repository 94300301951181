.error-boundary-container {
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-boundary-title {
  color: var(--ion-color-primary-contrast);
}

.error-boundary-text {
  text-align: center;
}

.error-boundary-text span {
  color: var(--ion-color-secondary);
  text-decoration: underline;
}
.error-boundary-text span:hover {
  cursor: pointer;
}

.error-boundary-container svg {
  width: 18.75rem;
  fill: var(--ion-color-primary-contrast);
}
