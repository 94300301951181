.questionGroup {
  margin: unset;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: unset;
}

.questionGroup svg {
  width: calc(4.5 * var(--default_increment));
  color: var(--ion-color-tertiary);
  cursor: pointer;
}

label {
  display: flex;
}

input:focus-visible + label {
  border: 2px solid var(--ion-color-black);
}

input[aria-disabled='true'] + label svg {
  color: var(--ion-color-grey);
  cursor: not-allowed;
}
