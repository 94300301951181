.buttonLabel {
  font-size: var(--font-size-small);
  font-weight: bold;
}

.dateTimeInputs {
  display: flex;
  align-items: center;
  flex: 1;
  gap: var(--default_increment);
  margin-top: calc(2 * var(--default_increment));
}

.dateTimeInputsDivider {
  white-space: nowrap;
}

.dateInput {
  flex: 1;
}
