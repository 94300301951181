.modalContentShare ion-button {
  display: flex;
  justify-content: center;
  margin: 0 0 var(--component-spacing) 0;
}

.modalContentShare svg {
  width: 1.25rem;
}

.shareByEmail {
  --background: var(--icon-color-mail);
  color: var(--ion-color-white);
  --background-hover: unset;
  --background-hover-opacity: 0.5;
}

@media screen and (min-width: 768px) {
  .modalContentShare {
    min-width: 24rem;
    padding-bottom: var(--default_increment);
  }
}
