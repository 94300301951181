.SideBarItemWrapper {
  padding: var(--default_increment);
  cursor: pointer;
  background: var(--ion-color-primary-darker);
}

.SideBarItem {
  display: flex;
  color: var(--ion-color-primary-contrast);
  line-height: calc(var(--default_increment) * 2.5);
  font-weight: 700;
  align-items: flex-start;
}

.SideBarItem a {
  color: unset;
}

.collapsed {
  padding-left: 0;
}

.SideBarItemWrapper:hover {
  background: var(--ion-color-primary);
  border-radius: calc(var(--default_increment) * 0.5);
}

.iconWrapper {
  display: flex;
}

.skeletonIconWrapper {
  display: flex;
  align-items: center;
  float: left;
  min-height: 36px;
}

.icon {
  display: flex;
  width: calc(var(--default_increment) * 2.5);
  height: calc(var(--default_increment) * 2.5);
  margin-right: var(--default_increment);
  align-items: center;
}

.title {
  min-height: calc(var(--default_increment) * 2.5);
}

.title span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}

.active {
  background: var(--ion-color-secondary);
  border-radius: calc(var(--default_increment) * 0.5);
}

.customTooltipCollapsed {
  margin-left: 25px;
  font-weight: 400;
}

.customTooltipFull {
  margin-left: 30px;
  font-weight: 400;
}

.customTooltip {
  display: flex;
  width: 100%;
}
