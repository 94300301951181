.totalHeader {
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--default_increment));
}

.headerTitleWrapper {
  display: flex;
  justify-content: space-between;
}

h2.headerTitleWrapper {
  align-items: center;
}

.headerWrap {
  flex-wrap: wrap;
}

.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: var(--default_increment);
}

p.headlineXS {
  font-size: var(--font-size-large);
  font-weight: bold;
  line-height: var(--line-height-small);
  color: var(--ion-color-primary-darker);
  display: inline-flex;
  gap: var(--increment);
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

p.bodySBold {
  display: flex;
  font-size: var(--font-size-small);
  font-weight: bold;
  line-height: var(--line-height-xsmall);
}

.withShortMargin {
  margin-bottom: calc(0.1 * var(--default_increment));
  margin-top: calc(0.1 * var(--default_increment));
}

.divider {
  padding-top: var(--default_increment);
  border-top: 1px solid var(--ion-color-primary-lighter);
}
