.contactsListCard ion-card-content {
  display: grid;
  grid-template-columns: 1fr max-content max-content max-content;
  grid-auto-flow: dense;
}

@media screen and (min-width: 960px) {
  .contactsListCard ion-card-content {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
  }
}

.hintMessage {
  order: 3;
  padding-top: 1rem;
  text-align: center;
  border-top: solid 0.063rem var(--ion-color-primary-light);
  composes: bodySDefault from global;
}
