.formColumn {
  display: flex;
  gap: calc(var(--default_increment) * 2);
}

.formColumn > div {
  width: 100%;
}

.helperText {
  padding-top: var(--default_increment);
  display: inline-block;
}

@media screen and (max-width: 767.99px) {
  .formColumn {
    display: unset;
  }
}
