.iconLocationWrapper {
  display: flex;
  align-items: center;
  gap: var(--default_increment);
  color: var(--ion-color-primary);
}

.title {
  text-decoration-line: underline;
  color: var(--ion-color-primary);
}
