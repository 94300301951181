@value withNavBar from '../DefaultPage/DefaultPage.module.css';

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  min-height: 100%;
}

.withoutBottomBar {
  padding-bottom: var(--column-spacing);
}

.topContent {
  width: var(--two-columns-layout-width);
  margin-bottom: var(--column-spacing);
}

.topContentSuccessWeb {
  display: none;
}

.topContentSuccessMobile {
  max-width: var(--column-width);
}

.topContentMobile {
  width: 100%;
  margin-bottom: var(--column-spacing);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  max-width: 100%;
}

.noGrow {
  flex-grow: unset;
}

.doubleColumn {
  justify-content: flex-start;
}

.doubleColumn .mainContainer {
  flex-direction: column;
}

.column {
  width: var(--column-width);
  max-width: 100%;
  flex-grow: 0;
  margin-bottom: var(--column-spacing);
  display: flex;
  flex-direction: column;
}

.twoColWidth {
  width: var(--two-columns-layout-width);
}

.columnInner {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.centered .columnInner {
  justify-content: center;
  align-items: center;
}

.sideImage {
  display: none;
}

.sideImageShowMobile {
  display: flex;
  width: 100%;
  height: 100%;
}

.sideImageShowMobileWrapper {
  flex-direction: column-reverse;
  justify-content: flex-end;
}
.sideImageShowMobileWrapperCentered {
  justify-content: center;
}

.sideImage svg,
.sideImage img {
  flex-grow: 1;
  width: 100%;
}

.bottomBarCenterWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bottomBar {
  width: var(--column-width);
  margin-top: var(--column-spacing);
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}

.fixedBarWrapper {
  position: fixed;
  bottom: var(--column-spacing);
  margin: 0 auto;
  max-width: calc(100% - calc(4 * var(--default_increment)));
  z-index: 100;
}

/* Apply only for large devices (newer than iphone 8) with statusbar at thhe bottom*/
@media only screen and (min-height: 737px) {
  .withNavBar .fixedBarWrapper.IosMobile {
    margin-bottom: calc(var(--navbar-horizontal-height) + (calc(5 * var(--default_increment))));
  }
}

/* .layout:not(.withNavBar) .fixedBarWrapper {
  margin-bottom: calc(2 * var(--default_increment));
} */

.mainContainer {
  width: 100%;
  display: flex;
  flex: 1;
}

.stepper {
  width: var(--column-width);
  max-width: calc(100% - calc(4 * var(--default_increment)));
  margin-bottom: var(--column-spacing);
  flex-shrink: 1;
}

/* Column width + 2 times spacing on sides */
@media screen and (min-width: 372px) {
  .mainContainer {
    width: var(--column-width);
  }
}

@media screen and (min-width: 540px) {
  .sideImageShowMobile {
    width: var(--column-width);
    height: var(--column-width);
  }
}

@media screen and (min-width: 960px) {
  .layout {
    padding-left: 0;
    padding-right: 0;
  }

  .centered {
    justify-content: center;
    align-items: center;
  }

  .verticalCenterTwoColumn {
    align-items: center;
  }

  .horizontalcenterTwoColumn {
    justify-content: center;
  }

  .doubleColumn .mainContainer {
    flex-direction: row;
  }
  .mainContainer .column:last-of-type,
  .layout.withSideImage .mainContainer .column:first-of-type {
    margin-bottom: 0;
  }
  .layout:not(.trippleColumn) .secondColumn {
    margin-left: var(--column-spacing);
  }
  .layout:not(.withSideImage) .mainContainer {
    justify-content: center;
  }
  .sideImage {
    display: flex;
    position: fixed;
    z-index: -1;
    margin-left: var(--column-spacing);
    transform: translateX(100%);
  }

  .layout:not(.trippleColumn) .mainContainer {
    width: var(--two-columns-layout-width);
  }

  .bottomBar:not(.fixedBarWrapper) {
    margin-bottom: calc(2 * var(--default_increment));
  }
  /* .layout.withNavBar .bottomBar {
    margin-bottom: 0;
  } */

  .fixedBarWrapper {
    padding: var(--column-spacing) 0;
    padding-bottom: 0;
    position: sticky;
    max-width: none;
  }

  .topContentSuccessWeb {
    display: block;
  }

  .topContentSuccessMobile {
    display: none;
  }
}

@media screen and (max-width: 959.9px) {
  .withNavBar .fixedBarWrapper {
    margin-bottom: calc(var(--navbar-horizontal-height));
  }
}

/*
* Set of rules for page with no navBar
* 1116px:
*  - 2*24px on both sides of the Page,
*  - 2*24px for spacing between columns,
*  - 3*340px for columns
*/
/* @media screen and (max-width: 1115px) {
  .layout:not(.withNavBar).trippleColumn .mainContainer {
    flex-direction: column;
  }
} */

/* @media screen and (min-width: 1116px) {
  .layout:not(.withNavBar) {
    padding-left: var(--column-spacing);
    padding-right: var(--column-spacing);
  }
} */

/*
* Set of rules for page with navBar
* 1222px:
*  - 130px for navbar with spacings on both sides
*  - 24px on right side of the Page,
*  - 2*24px for spacing between columns,
*  - 3*340px for columns
*/
/* @media screen and (max-width: 1221px) {
  .layout.withNavBar.trippleColumn .mainContainer {
    flex-direction: column;
  }
} */

/* @media screen and (min-width: 1222px) {
  .layout.withNavBar {
    padding-right: var(--column-spacing);
  }
} */
