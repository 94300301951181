.reactionsSummary {
  border-bottom: 1px solid var(--ion-color-primary-lighter);
  padding: var(--default_increment);
}

.reactionsWrapper {
  width: calc(100% + (calc(4 * (var(--default_increment)))));
  margin: calc(-2 * (var(--default_increment)));
}

.markdownWrapper {
  margin: var(--default_increment) 0;
}

@media (min-height: 640px) {
  .titleSide {
    margin-top: 16px;
  }
}

@media (min-width: 768px) {
  .titleSide {
    margin-top: 0px;
  }
}

.titleMain {
  display: none;
}

@media (min-width: 1024px) {
  .titleSide {
    display: none;
  }

  .titleMain {
    display: block;
  }
}
