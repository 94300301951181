/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #283897;
  --ion-color-primary-darker: #131a46;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-shade: #233184;
  --ion-color-primary-tint: #414fa3;
  --ion-color-primary-light: #7c8adc;
  --ion-color-primary-lighter: #c5cbeb;
  --ion-color-primary-ultralight: #e1e4f7;
  --ion-color-primary-lightest: #f5f6fc;

  /** secondary **/
  --ion-color-secondary: #3862f8;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-shade: #3156da;
  --ion-color-secondary-tint: #4f74f8;
  --ion-color-secondary-light: #ccd7fd;

  /** tertiary **/
  --ion-color-tertiary: #53dcc8;
  --ion-color-tertiary-light: #d2f6f1;
  --ion-color-tertiary-contrast: #2a295c;
  --ion-color-tertiary-shade: #49c1b0;
  --ion-color-tertiary-tint: #67e0ce;

  /* tertiary contrast text */
  --ion-color-tertiary-contrast-text: #000;

  /** success **/
  --ion-color-success: #006245;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-success-light: #e4fff7;

  /** warning **/
  --ion-color-warning: #ff7945;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-shade: #f36f3b;
  --ion-color-warning-tint: #ff844f;

  /** danger **/
  --ion-color-danger: #b30000;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-danger-focus: #d14e03;
  --ion-color-danger-light: #ffeeee;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-contrast: #000000;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-contrast: #2a295c;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;

  /** black **/
  --ion-color-black: #000000;

  /** non themable colors **/
  --ion-color-grey: #93939d;
  --ion-color-grey-darker: #60606a;
  --ion-color-grey-lighter: #e3e3e6;
  --ion-color-grey-ultralight: #f1f1f2;

  /** custom **/
  /* Equivalent of 4px for possibility to use more precise increments */
  --increment: 0.25rem;
  --default_increment: 8px;

  /**new colors**/
  /** skin **/
  --ion-color-skin: #fcbd4c;

  /** state **/
  --icon-color-green-medium: #009569;
  --icon-color-green-light: #c8ffef;
  --icon-color-green-red: #ee0000;
  --icon-color-mail: #267cb5;
  --ion-color-state-red-dark: #bb0000;

  --ion-font-family: 'Open Sans', sans-serif;

  /** shadows **/
  --default-shadow: 0px 2px 4px rgba(124, 138, 220, 0.24);

  --input-radio-checkbox-size: 1.25rem;
  --border-radius-sm: 0.1875rem;
  --border-radius-md: 0.5rem;

  --scrollbar-width: 0.25rem;
  /** reactions **/
  --like-color: 56, 98, 248;
  --love-color: 238, 0, 0;
  --interesting-color: 251, 133, 0;
  --congrate-color: 100, 40, 220;

  /** Default font sizes **/
  --font-size-xxxxlarge: 3rem;
  --font-size-xxxlarge: 2rem;
  --font-size-xxlarge: 1.75rem;
  --font-size-xlarge: 1.5rem;
  --font-size-large: 1.25rem;
  --font-size-medium: 1.125rem;
  --font-size-default: 1rem;
  --font-size-small: 0.875rem;
  --font-size-xsmall: 0.75rem;

  /** Line heights **/
  --line-height-xlarge: 3.5rem;
  --line-height-large: 2.5rem;
  --line-height-medium: 2rem;
  --line-height-small: 1.5rem;
  --line-height-xsmall: 1.25rem;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white) !important;
  --ion-color-contrast: var(--ion-color-white-contrast) !important;
  --ion-color-shade: var(--ion-color-white-shade) !important;
  --ion-color-tint: var(--ion-color-white-tint) !important;
}

.contrast {
  --ion-color-primary: var(--ion-color-primary-contrast);
}

/*
* Layout system
*/
:root {
  --sidebar-vertical-width-full: 14rem;
  --sidebar-vertical-width-collapsed: 4.25rem;
  --navbar-horizontal-height: 4.1875rem;
  --column-width: 21.25rem;
  --column-spacing: 1.5rem;
  --component-spacing: 0.5rem;
  --two-columns-layout-width: calc((var(--column-width) * 2) + var(--column-spacing));
  --three-columns-layout-min-width: calc(
    var(--two-columns-layout-width) + var(--column-width) + var(--column-spacing)
  );
  --three-columns-layout-max-width: calc(
    var(--two-columns-layout-width) + var(--column-width) + (var(--column-spacing) * 3)
  );

  --default-content-size: 340px;
  --default-background-color: #f5f6fc;
}
