.progressLabel {
  display: flex;
  justify-content: center;
  color: var(--ion-color-secondary);
  flex: 1;
}

.row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  gap: var(--default_increment);
}

.wrapperBar {
  width: 100%;
  height: var(--default_increment);
  background-color: var(--ion-color-primary-lightest);
  flex: 4;
}

.progressBar {
  height: 100%;
  background-color: var(--ion-color-secondary);
  transition: 0.3s ease-in-out;
}

.round {
  border-width: calc(var(--default_increment) / 8);
  border-style: solid;
  border-color: var(--ion-color-secondary);
  border-radius: var(--border-radius-md);
}
