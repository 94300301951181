#ProductsList_more_info_container {
  display: flex;
  justify-content: flex-end;
}

#ProductsList_more_info {
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

#ProductsList_more_info svg {
  width: 24px;
}

ion-button.hideTextOnMobile {
  --padding-start: 0px;
  --padding-end: 0px;
}

.hideTextOnMobile > span > span:first-child {
  margin-right: unset;
}

.hideTextOnMobile > span > span:first-child > svg {
  width: 2rem;
  height: 2rem;
}

.hideTextOnMobile > span > span:nth-child(2) {
  display: none;
}

@media screen and (min-width: 960px) {
  .hideTextOnMobile > span > span:first-child {
    margin-right: 0.5rem;
  }

  .hideTextOnMobile > span > span:first-child > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  .hideTextOnMobile > span > span:nth-child(2) {
    display: inline;
  }
}
