.inputWrapper {
  display: flex;
  position: relative;
}

.input {
  --padding-top: var(--default_increment);
  --padding-end: var(--default_increment);
  --padding-bottom: var(--default_increment);
  --padding-start: var(--default_increment);
  z-index: 1;
  --border-radius: var(--default_increment);
}

[placeholder] {
  text-overflow: ellipsis;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.input input[type='number'] {
  appearance: textfield;
}

.input input:global(.native-input) {
  --placeholder-color: var(--ion-color-primary-light);
  --placeholder-opacity: 1;
  color: var(--ion-color-primary-darker);
  height: 2.5rem;
}

.input input:global(.native-input[disabled].sc-ion-input-md),
.input input:global(.native-input[disabled].sc-ion-input-ios) {
  opacity: 1;
}

.inputIcon {
  width: 1.25rem;
  height: 1.25rem;
  align-self: center;
  z-index: 1;
  position: relative;
}

.passwordIcon {
  padding-left: calc(var(--default_increment) * 0.75);
  padding-right: calc(var(--default_increment) * 0.75);
}

.leftInputIcon {
  margin-left: var(--default_increment);
}

.rightInputIcon {
  margin-right: var(--default_increment);
}

.msg {
  margin-top: var(--default_increment);
  display: block;
  composes: bodySDefault from global;
}

.validInputWrapper + .statusMessage {
  color: var(--ion-color-success);
}

.errorInputWrapper + .statusMessage {
  color: var(--ion-color-danger);
}

:global(.input-clear-icon.sc-ion-input-md) {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: white;
  background-color: var(--ion-color-primary);
  background-image: none;
  margin-right: var(--default_increment);
  display: none;
}

:global(.input-clear-icon.sc-ion-input-md):after {
  display: inline-block;
  content: '\00d7'; /* This will render the 'X' */
  font-size: 20px;
}

:global(.has-value.sc-ion-input-md-h .input-clear-icon.sc-ion-input-md) {
  display: block;
}

::placeholder {
  color: var(--ion-color-primary-light);
  opacity: 1;
}
