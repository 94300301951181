.contentWrapper {
  overflow: hidden;
  transition: height 500ms ease;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
}

.titleWrapper:focus {
  box-shadow: 0 0 0 3px var(--ion-color-primary-lighter);
  transition: box-shadow 0.2s ease;
}

.chevronWrapper {
  min-width: 1.5rem;
  color: var(--ion-color-primary-darker);
  transition: transform 500ms ease;
}

.chevronOpen {
  transform: rotate(180deg);
}
