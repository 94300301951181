@value actionsBar from '../ActionsBarV2/ActionsBar.module.css';
@value static from '../ActionsBarV2/ActionsBar.module.css';

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--column-spacing);
  z-index: 0;
}

.flex {
  flex: 1;
}

/* Remove flex grow for static actionsBar usage */
.flex:has(~ .actionsBar.static) {
  flex: 0;
}

/* Idea, move behavior to container */

.fix {
  order: -1;
  z-index: 1;
}

.complementary {
  display: none;
}

.complementary > svg {
  width: 21.5rem;
  height: 21.5rem;
}

@media screen and (min-width: 1024px) {
  .fix {
    max-width: var(--column-width);
    flex-shrink: 0;
    order: unset;
    position: sticky;
    z-index: 0;
    top: var(--column-spacing);
  }

  /* This is here to fix white-space: nowrap in Tile component with ellipsis stretching parents */
  .flex {
    max-width: calc(100% - var(--column-spacing) - var(--column-width));
  }

  .complementary {
    display: flex;
  }
}
