.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--ion-color-primary-lighter);
  border-radius: 0.5rem;
  min-height: 7rem;
}

.icon {
  height: 2rem;
  margin-bottom: calc(1.5 * var(--default_increment));
}

.scheduleEntry {
  padding-bottom: calc(2 * var(--default_increment));
  margin-bottom: calc(1.5 * var(--default_increment));
}

.scheduleEntry:not(:last-child) {
  border-bottom: 1px solid var(--ion-color-primary-ultralight);
}

.scheduleEntry > *:not(:first-child) {
  margin-top: calc(var(--default_increment) / 2);
}

.arrivals {
  color: var(--ion-color-primary);
}

.duration {
  display: flex;
  color: var(--ion-color-secondary);
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.durationIcon {
  display: flex;
  align-items: center;
  margin-right: var(--default_increment);
}
