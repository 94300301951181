.loader,
.loader:after {
  border-radius: 50%;
  width: 3.2em;
  height: 3.2em;
  display: block;
  content: '';
}
.loader {
  margin: 0;
  font-size: var(--loader-size);
  position: relative;
  text-indent: -9999em;
  border-top: 0.4em solid var(--loading-background-color);
  border-right: 0.4em solid var(--loading-background-color);
  border-bottom: 0.4em solid var(--loading-background-color);
  border-left: 0.4em solid var(--loading-foreground-color);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  --loading-foreground-color: var(--ion-color-primary-darker);
  --loading-background-color: var(--ion-color-primary-lighter);
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
