.actionBar div {
  background-color: white !important;
}

.checkboxWrapper {
  display: inline-block;
  padding-right: var(--default_increment);
}

.labelWithLink {
  display: inline;
}

.labelWithLink a {
  font-weight: bold;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  white-space: normal;
}

.wrapper ul {
  margin: 0;
}

.notificationWrapper {
  margin-bottom: calc(var(--default_increment) * 3);
}

.titleIcon {
  height: 2rem;
  width: 3rem;
}

.titleSuffix {
  display: block;
}
