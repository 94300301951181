.filterButton {
  width: 100%;
  height: 100%;
}

.pageHeader {
  width: 100%;
}

.facility {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--default_increment);
}

.scan {
  text-align: center;
  width: 100%;
  margin-top: var(--default_increment);
}

.moment {
  display: flex;
  gap: 0.75rem;
}

.facilityTitle {
  margin-bottom: var(--default-increment);
}

.facilityInfo {
  display: flex;
}

.facilityDetailsText {
  display: none;
  vertical-align: middle;
  text-decoration: underline;
}

.vegeBadge {
  border-radius: 4px;
}

.dateIcon {
  padding-right: 0.28rem;
}

.dateString {
  font-size: var(--font-size-default);
  line-height: 1.5;
  letter-spacing: 0.015em;
  color: var(--ion-color-primary-darker);
  margin: unset;
  display: flex;
  justify-content: space-between;
}

.notificationsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--default_increment);
}

.hideActionsBar {
  display: none;
}

.containerWithPadding {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}

@media screen and (min-width: 960px) {
  .facilityDetailsText {
    display: block;
  }

  .facilityDetailsIcon {
    margin-right: 0.1875rem;
  }

  .dateIcon {
    vertical-align: middle;
    padding-right: 0.28rem;
  }
}

@media screen and (min-width: 1024px) {
  .hideActionsBar {
    display: block;
  }
}
