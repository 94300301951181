.iframePage {
  width: 100%;
  position: relative;
  padding-top: var(--column-spacing);
  padding-left: calc(1 * var(--default_increment));
  padding-right: calc(1 * var(--default_increment));
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.iframePage :global(.slick-track) {
  margin-left: 0;
}

.iframeInner {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding-bottom: var(--default_increment);
}

@media screen and (min-width: 960px) {
  .iframePage {
    display: block;
    padding-right: var(--default_increment);
    padding-left: calc(
      var(--navbar-vertical-width) + var(--column-spacing) + 3 * var(--default_increment)
    );
    padding-bottom: var(--column-spacing);

    margin-bottom: 0;
  }

  .iframePage > div {
    margin-bottom: 0;
  }
}
