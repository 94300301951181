.wrapper {
  display: flex;
  background: var(--ion-color-primary-contrast);
  box-shadow: var(--default-shadow);
  border-radius: var(--default_increment);
  height: calc(10 * var(--default_increment));
}

.wrapper > *,
.wrapper :global(.button) {
  display: flex;
  flex: 1 1 0px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.wrapper > *:not(:last-child) {
  border-right: 1px solid var(--ion-color-primary-lighter);
}

.wrapper > *:first-child,
.wrapper > *:first-child ion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.wrapper > *:last-child,
.wrapper > *:last-child ion-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.wrapper :global(.button) ion-button :global(.buttonContent) {
  flex-direction: column;
}

.wrapper :global(.button) ion-button :global(.buttonContent) span {
  margin-right: 0rem !important;
  line-height: calc(5 * var(--increment));
}

.wrapper :global(.button) ion-button :global(.buttonContent) span svg {
  width: calc(3 * var(--default_increment));
  height: calc(3 * var(--default_increment));
}

.wrapper :global(.button) ion-button :global(.buttonContent) span:nth-child(2),
.wrapper :global(.button) ion-button :global(.buttonContent) span:nth-child(2) span {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: calc(5 * var(--default_increment));
}

@container (min-width: 500px) {
  .wrapper {
    height: calc(7 * var(--default_increment));
  }

  .wrapper :global(.button) ion-button :global(.buttonContent) {
    flex-direction: row;
    justify-content: center;
    column-gap: var(--default_increment);
  }

  .wrapper :global(.button) ion-button :global(.buttonContent) span:nth-child(2),
  .wrapper :global(.button) ion-button :global(.buttonContent) span:nth-child(2) span {
    text-align: left;
  }
}

@supports not (container-type: inline-size) {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .wrapper {
      height: calc(7 * var(--default_increment));
    }

    .wrapper :global(.button) ion-button :global(.buttonContent) {
      flex-direction: row;
      justify-content: center;
      column-gap: var(--default_increment);
    }
    .wrapper :global(.button) ion-button :global(.buttonContent) span:nth-child(2),
    .wrapper :global(.button) ion-button :global(.buttonContent) span:nth-child(2) span {
      text-align: left;
    }
  }
}
