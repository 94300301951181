.service_request_status_open {
  color: var(--ServiceRequest-status-open);
}

.service_request_status_pending {
  color: var(--ServiceRequest-status-pending);
}

.service_request_status_closed {
  color: var(--ServiceRequest-status-closed);
}

.statusIcon svg {
  width: 1.1rem;
  margin-right: calc(var(--default_increment) / 2);
}
