.widgetsContainer {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: var(--column-spacing);
}

@media (min-width: 1336px) {
  .widgetsContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
