.attachment {
  display: inline-flex;
  flex-direction: column;
  width: 100px;
  align-items: stretch;
  color: var(--color);
}

.attachment span {
  text-decoration: underline;
}

.imageContainer {
  position: relative;
  box-shadow: var(--default-shadow);
  margin-bottom: var(--default_increment);
  border-radius: 8px;
  width: 100px;
  height: 100px;
}

.removeButton {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--ion-color-primary-contrast);
  background-color: var(--ion-color-secondary);
  padding: 0.3rem;
  border-radius: 8px;
  width: 1.5rem;
  height: 1.5rem;
}

.attachmentImage {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
}

.attachmentIcon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attachmentIcon svg {
  color: var(--ion-color-primary-light);
}

.openModalButton {
  border: none;
  padding: 0;
  background: transparent;
  border-radius: 8px;
}

.loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
