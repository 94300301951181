.sideBarHeader {
  background: var(--ion-color-primary);
  padding: calc(var(--default_increment) * 2.5);
  line-height: calc(var(--default_increment) * 2.5);
  position: relative;
  display: flex;
  font-weight: 700;
  align-items: center;
  color: var(--ion-color-primary-contrast);
  height: 60px;
}

.mobile {
  margin-top: calc(var(--default_increment) * 5.5);
}

.toggleButton {
  background: transparent;
  border: none;
  padding: calc(var(--default_increment) * 0.5) 0;
  line-height: 0;
  cursor: pointer;
  color: var(--ion-color-primary-contrast);
}

.collapsed {
  justify-content: center;
}

@media screen and (max-width: 720px) {
  .sideBarHeader {
    height: 54px;
  }
}

.full {
  justify-content: space-between;
}
