.link {
  margin-top: calc(var(--default_increment) / 2);
  display: inline-flex;
  align-items: center;
  color: var(--title-color);
}
.link span {
  text-decoration: underline;
  font-size: var(--font-size-small);
  font-weight: 600;
}
.link svg {
  height: 1em;
  margin-left: var(--default_increment);
}
.link:hover span {
  text-decoration: none;
}
