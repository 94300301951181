.bottomWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f9faff;
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--default_increment));
  padding: calc(2 * var(--default_increment));
}

.actionsContainerBrowser {
  display: flex;
  gap: calc(3 * var(--default_increment));
  flex-grow: 1;
}

.actionsContainerNative {
  display: flex;
  flex-direction: column;
  gap: var(--default_increment);
}

.closeBtn {
  margin-top: var(--default_increment);
}

.containerScanner {
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--default_increment));
}

.divider {
  border: 0.5px solid var(--ion-color-primary-light);
  margin-top: calc(2 * var(--default_increment));
}

.browserScanner {
  min-width: 340px;
}
