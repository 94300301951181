.wrapper {
  position: relative;
}

.options {
  position: absolute;
  display: block;
  top: 0;
  transform: translateY(-100%);
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.inList .options {
  transform: none;
  z-index: 1;
}

.childrenContainer {
  width: inherit;
}

.options:after {
  display: block;
  content: '';
  width: 100%;
  height: var(--default_increment);
}

/* .inList .options {
  right: 0;
  top: 0;
  transform: none;
  z-index: 1;
} */
