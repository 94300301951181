.card {
  padding: var(--default_increment);
  display: flex;
  flex-direction: row;
}

.leftArea {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: calc(var(--default_increment) * 2) 0 calc(var(--default_increment) * 2)
    var(--default_increment);
}

.rightArea {
  flex: 1;
  padding-left: var(--default_increment);
  display: flex;
  justify-content: flex-end;
}

.dummyContent {
  height: 24px;
  margin: calc(var(--default_increment) / 2) 0;
}

.button {
  height: 40px;
}
