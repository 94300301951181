.mainContainer {
  --spacing: calc(3 * var(--default_increment));
  --column-width: 21.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--spacing);
  width: 100%;
  max-width: var(--column-width);
}

@media screen and (min-width: 768px) {
  .mainContainer {
    --column-width: 26.5rem;
  }
}

.actionButton {
  width: 100%;
}

.titleCentered {
  text-align: center;
}
