.rangeWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.range {
  --bar-height: 0.5rem;
  --bar-background: var(--ion-color-primary-lightest);
  --bar-background-active: var(--ion-color-tertiary);
  --bar-border-radius: var(--border-radius-md);
  --knob-size: 1rem;
  --knob-box-shadow: 0;
  --knob-background: var(--ion-color-white);
  --knob-box-shadow: 0px 0px 0px 1px var(--ion-color-tertiary);
  padding: 0;
}

ion-range.focused::part(knob) {
  box-shadow: 0 0 0 3px var(--ion-color-primary-lighter);
  transition: box-shadow 0.2s ease;
}

.range::part(pin) {
  font-size: var(--font-size-small);
  font-weight: bold;
  line-height: 0;
  letter-spacing: 0.02em;
  color: var(--ion-color-primary);
}

.limitsWrapper {
  composes: bodySDefault from global;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: var(--ion-color-primary);
  font-feature-settings: 'pnum' on, 'lnum' on;
  padding: 0;
  margin: 0;
}
