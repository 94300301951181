.expandedFilterWithButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.expandedFilterButtonContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}

.expandedFilterButton {
  margin-right: 0.625rem;
}
.expandedFilterButton span {
  max-width: 16ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expandedFilterButtonContainer::-webkit-scrollbar {
  display: none;
}

.leftArrow,
.rightArrow {
  display: none;
}

@media screen and (min-width: 768px) {
  .expandedFilterButtonContainer {
    width: 100%;
  }

  .leftArrow,
  .rightArrow {
    display: none;
    position: sticky;
    background: white;
    z-index: 999;
    top: 0.25rem;
    left: 0;
    border: 2px solid var(--ion-color-secondary);
    color: var(--ion-color-secondary);
  }
  .leftArrowEnabled,
  .rightArrowEnabled {
    display: block;
  }
  .rightArrow {
    padding-bottom: 0.188rem;
  }

  .expandedFilterButtonContainer::-webkit-scrollbar {
    display: none;
  }
}

ion-router-outlet {
  overflow: clip;
}