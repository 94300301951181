.skeletonOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--default_increment) 0;
}

.skeletonOption:first-child {
  padding-top: 0;
}

.skeletonOption:last-child {
  padding-bottom: 0;
}

.skeletonOption + .skeletonOption {
  border-top: 1px solid var(--ion-color-primary-ultralight);
}
