.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  white-space: normal;
}

.notificationWrapper {
  margin-bottom: calc(var(--default_increment) * 3);
}

.geographiesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.geographiesWrapper .card {
  width: var(--default-content-size);
  justify-content: center;
  margin: calc(var(--default_increment) * 1.5) 0;
}

.legalTitle {
  align-items: center;
}

.titleIcon {
  height: 2rem;
  width: 2rem;
  color: var(--ion-color-primary-darker);
}

.titleSuffix {
  display: block;
}

@media (max-width: 420px) {
  .geographiesWrapper .card {
    margin: var(--default_increment);
  }
}
