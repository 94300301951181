.mainContainer {
  --spacing: calc(3 * var(--default_increment));
  --column-width: 21.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--spacing);
  width: 100%;
  max-width: var(--column-width);
}

.sideImage {
  border-radius: var(--default_increment);
  background: var(--ion-color-primary-ultralight);
}

.actionButton {
  width: 100%;
}

@media screen and (max-width: 767.99px) {
  .mainColumn {
    flex: unset;
  }
}
