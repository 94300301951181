.dismissWrapper {
  padding: 0.375rem 0.375rem 0 0;
  margin-left: auto;
  flex-grow: 0;
}

.dismissContainer {
  display: flex;
}

.dismiss {
  background: none;
  margin-left: var(--default_increment);
  margin-top: 0.1rem;
  width: 1.37rem;
  height: 1.37rem;
  padding: 0;
  color: var(--color);
}
