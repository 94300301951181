ion-button {
  margin: 0rem;
  min-height: 2.75rem;
  --color: var(--ion-color-tertiary-contrast-text);
  text-transform: none !important;
  --box-shadow: none;
  --border-radius: 4px;
  border-radius: 4px;
  --padding-top: 0.75rem;
  --padding-bottom: 0.75rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
}

ion-button :global(.loading) {
  --loading-foreground-color: var(--ion-color-white);
  --loading-background-color: rgba(255, 255, 255, 0.2);
  --loader-size: 0.625rem;
}

.disabled {
  opacity: 1;
  --color: var(--ion-color-grey-darker);
  pointer-events: auto;
}

.disabled::part(native) {
  cursor: not-allowed;
}

.fullWidth {
  width: 100%;
}

.primary {
  --background: var(--ion-color-tertiary);
  --color: var(--ion-color-tertiary-contrast-text);
  --background-hover: var(--ion-color-tertiary-light);
  --background-hover-opacity: 1;
}

.primary:focus:not(:hover),
.primary:active {
  outline: 2px solid var(--ion-color-white);
  box-shadow: 0px 0px 8px 0px var(--ion-color-tertiary);
}

.primary:focus {
  --background: var(--ion-color-tertiary-light);
}

.primary:active,
.primary.active {
  --background: var(--ion-color-tertiary);
  --background-hover: var(--ion-color-tertiary);
}

.primary.disabled {
  box-shadow: none;
  outline: none;
  --background: var(--ion-color-grey-ultralight);
  --background-hover: var(--ion-color-grey-ultralight);
  --color: var(--ion-color-grey-darker);
}

.icon_primary * {
  color: var(--ion-color-tertiary-contrast-text);
}

.primary.disabled .elements span .icon_primary * {
  color: var(--ion-color-grey-darker);
}

.secondary {
  --background: transparent;
  border: 2px solid var(--ion-color-primary);
  --color: var(--ion-color-primary);
}
.secondary:hover:not(:active),
.secondary:focus:not(:active) {
  border: 2px solid var(--ion-color-secondary);
  --color: var(--ion-color-secondary);
}

.secondary:focus:not(:hover),
.secondary:active:not(.disabled),
.secondary.active {
  outline: 2px solid var(--ion-color-white);
  box-shadow: 0px 0px 8px 0px var(--ion-color-secondary);
}

.secondary.disabled,
.secondary.disabled:hover,
.secondary.disabled:focus {
  --color: var(--ion-color-grey-darker);
  border: 2px solid var(--ion-color-grey-darker);
}

.secondary_inline {
  --background: var(--ion-color-grey-ultralight);
  --color: var(--ion-color-black);
  --background-hover: var(--ion-color-grey-lighter);
  --background-hover-opacity: 1;
  --background-focused: var(--ion-color-grey-lighter);
  --background-focused-opacity: 1;
}

.secondary_inline:focus,
.secondary_inline:active {
  outline: 2px solid var(--ion-color-white);
  border-radius: var(--increment);
  box-shadow: 0px 0px 8px 0px var(--ion-color-tertiary);
}

.secondary_inline:active {
  --background: var(--ion-color-grey-ultralight);
}

.icon_secondary * {
  color: var(--ion-color-primary);
}

.secondary_on_primary {
  --background: transparent;
  --color: var(--ion-color-primary-contrast);
  border: 2px solid var(--ion-color-white);
}

.secondary_on_primary.disabled {
  --background: transparent;
  border: 2px solid var(--ion-color-primary-light);
}

.icon_secondary_on_primary * {
  color: var(--ion-color-primary-contrast);
}

.secondary:hover:not(:active) .elements span .icon_secondary *,
.secondary:focus .elements span .icon_secondary * {
  color: var(--ion-color-secondary);
}

.secondary:active .elements span .icon_secondary * {
  color: var(--ion-color-primary);
}

.secondary.disabled .elements span .icon_secondary *,
.secondary.disabled:hover .elements span .icon_secondary * {
  color: var(--ion-color-grey-darker);
}

.tertiary {
  --background: transparent;
  --color: var(--ion-color-primary);
  text-decoration: underline;
  font-weight: bold;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
}
.tertiary:hover,
.tertiary:focus {
  --color: var(--ion-color-secondary);
}

.tertiary:focus:not(:hover),
.tertiary:active,
.tertiary.active {
  --background: var(--ion-color-white);
  box-shadow: 0px 0px 8px 0px var(--ion-color-secondary);
}

.tertiary:active,
.tertiary.active {
  --color: var(--ion-color-primary);
}

.tertiary.disabled,
.tertiary.disabled:active {
  --color: var(--ion-color-grey-darker);
  box-shadow: none;
  --background: transparent;
}

/* TODO kept commented code to address underlying issue of icon styling for whole application */
/* .icon_tertiary * {
  color: var(--ion-color-primary);
} */

.tertiary_on_primary {
  --background: transparent;
  --color: var(--ion-color-primary-contrast);
  text-decoration: underline;
}

.icon_tertiary_on_primary * {
  color: var(--ion-color-primary-contrast);
}

.primary:focus,
.secondary:focus,
.secondary_inline:focus,
.tertiary:focus,
.secondary_on_primary:focus,
.tertiary_on_primary:focus {
  transition: box-shadow 0.2s ease;
}

.loadingButton:global() span {
  width: 2.34rem;
  height: 2.34rem;
}

.action_card {
  --background: var(--ion-color-tertiary);
  --color: var(--ion-color-primary-darker);
  --background-hover: var(--ion-color-tertiary-tint);
  --background-hover-opacity: 1;
  transition: 0.2s all;
  font-weight: bold;
  height: 100%;
  width: 100%;
  margin: 0rem;
}

.elements {
  display: flex;
  align-items: center;
  flex: 1 0;
  width: 100%;
  line-height: 1.25rem;
}

.elements.spaced {
  justify-content: space-between;
}

.elements.alignTop {
  align-self: flex-start;
}

.elements.centered {
  justify-content: center;
}

.affix:not(*:only-child) {
  margin-right: 0.5rem;
}

.suffix:not(*:only-child) {
  margin-left: 0.5rem;
}

.affix,
.suffix {
  position: relative;
  display: flex;
  align-items: center;
}
.elements span:first-child {
  text-align: left;
}

.elements span:nth-child(3) {
  text-align: right;
}

.elements span:only-child {
  text-align: center;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
}

.wrapper {
  position: relative;
  display: contents;
}

.buttonInline {
  display: inline-block;
}

.badge {
  position: absolute;
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast-text);
  border-radius: var(--default_increment);
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-top: 0;
  top: -4px;
  left: -4px;
}

.badgeInner {
  top: -8px;
  left: -8px;
}

.badgePrimary {
  border: 1px solid var(--ion-color-tertiary);
  background-color: var(--ion-color-white);
}

.badgeRight {
  left: initial;
  right: -4px;
}

.badgeInner.badgeRight {
  right: -8px;
}

.textMultiline {
  text-overflow: ellipsis;
  white-space: normal;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: calc(3 * var(--default_increment));
}

.column {
  flex-direction: column;
}

/*
* Sizes
*/
.smallButton {
  --padding-start: 0.625rem;
  --padding-end: 0.625rem;
  min-height: 2.5rem;
}

.smallButton.loadingButton:global() span {
  width: 2em;
  height: 2em;
}

/*
* Auto size SVG icons
*/
:global(.button) svg {
  width: 1.25rem;
}

:global(.button).smallButton svg {
  width: 1rem;
}
