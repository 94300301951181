.questionHeaderContainer {
  display: flex;
  justify-content: center;
}

.title {
  margin-top: 0px;
  font-size: calc(var(--increment) * 7);
  font-weight: 400;
}

.questionHeaderContainer h2 {
  width: var(--column-width);
}

h5.questionTitle {
  font-weight: 600;
}

.questionAnswer {
  margin-top: calc(var(--default_increment) * 2);
}

.infoMessageContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.mainColumn {
  flex: unset;
  row-gap: unset;
}
