hgroup h2,
hgroup h3,
.noticeText {
  text-align: center;
  color: var(--ion-color-primary-darker);
  margin: unset;
}

hgroup {
  display: flex;
  justify-content: center;
  margin-bottom: calc(3 * var(--default_increment));
}

.noticeNote {
  margin-top: var(--default_increment);
}

.sideImage {
  border-radius: var(--default_increment);
  align-items: flex-start;
}

.mainContainer {
  --spacing: calc(3 * var(--default_increment));

  display: flex;
  flex-direction: column;
  row-gap: var(--spacing);
  width: 100%;
  max-width: var(--column-width);
  text-align: center;
}
.mainColumn {
  align-items: flex-end;
}
.noticeContainer {
  height: unset;
}

@media screen and (max-width: 1023px) {
  .noticeContainer {
    height: 90vh;
  }
  .noticeContainer svg {
    width: 100%;
    max-width: 328px;
    max-height: 328px;
    margin: 0 0 calc(3 * var(--default_increment)) 0;
  }

  .mainColumn {
    flex: unset;
    height: 100vh;
    align-items: center;
  }

  .mainContainer {
    height: 100vh;
    max-width: unset;
  }

  .sideImage {
    align-items: center;
  }
}
