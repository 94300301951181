.wrapper {
  display: flex;
  align-items: center;
  gap: var(--default_increment);
}

.inputs {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: calc(2 * var(--default_increment));
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconOrigin {
  width: 0.75rem;
  height: 0.75rem;
  border: 1px solid var(--ion-color-primary-light);
  border-radius: 100%;
  margin-bottom: var(--default_increment);
}

.iconPath {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  margin-bottom: var(--default_increment);
}

.iconPathDot {
  width: 0.25rem;
  height: 0.25rem;
  background: var(--ion-color-primary-lighter);
  border-radius: 100%;
}

.iconDestination {
  stroke: var(--ion-color-primary-darker);
}

.optionsFetchingPlaceholder {
  text-align: left;
}
