.widgetPlaceholder {
  border: 2px dashed var(--ion-color-primary-lighter);
  border-radius: 8px;
  padding-top: var(--default_increment);
  padding-bottom: var(--default_increment);
}

.widgetPlaceholder :global(.InfoMessage div svg) {
  width: 2rem;
}
