.accordion {
  margin-bottom: var(--default_increment);
  border-bottom: 1px solid var(--ion-color-primary-darker);
}

.checkboxWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: var(--default_increment);
  padding-top: var(--default_increment);
  padding-bottom: var(--default_increment);
  border-bottom: 1px solid var(--ion-color-primary-lighter);
}

.checkboxWrapper:last-child {
  margin-bottom: var(--column-spacing);
}

.titleWrapper {
  width: 100%;
  cursor: pointer;
}
