.title {
  align-items: center;
}

.description {
  color: var(--ion-color-primary-darker);
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

.iconWrapper {
  color: var(--ion-color-primary-darker);
  width: calc(4 * var(--default_increment));
  height: calc(4 * var(--default_increment));
  cursor: pointer;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: calc(2.5 * var(--default_increment));
}

.coffeeImage {
  height: 12.5rem;
  width: 12.5rem;
  border-radius: var(--default_increment);
}

.sideImage {
  border-radius: var(--default_increment);
  background: var(--ion-color-primary-ultralight);
}

.mainContainer {
  --spacing: calc(var(--default_increment));
  --column-width: 21.5rem;

  display: flex;
  flex-direction: column;
  row-gap: var(--spacing);
  width: 100%;
  max-width: var(--column-width);
  text-align: center;
}

@media screen and (max-width: 767.99px) {
  .mainColumn {
    flex: unset;
  }
}
