.actionButton {
  width: 100%;
}

.title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.5rem;
  text-align: center;
}
