.container > :global(.fileUploaderWrapper) > :global(.button) {
  display: flex;
  justify-content: center;
}

.container div div ion-button {
  margin-top: calc(2 * var(--default_increment));
}

.attachmentsContainer {
  display: flex;
  flex-wrap: wrap;
}

.attachmentsContainer > div:not(:last-child) {
  margin-right: var(--default_increment);
}

.attachmentsContainer > div {
  margin-top: calc(2 * var(--default_increment));
}

.errorMessageContainer {
  max-width: 350px;
}

.errorMessageContainer > div:first-child {
  margin-top: calc(2 * var(--default_increment));
}
.errorMessageContainer > div:nth-child(2) {
  margin-top: var(--default_increment);
}

.modalContent {
  display: flex;
  justify-content: center;
}

.downloadButton {
  width: 100%;
}

.previewImage {
  object-fit: contain;
}

.iconFileWrapper {
  background-color: var(--ion-color-primary-ultralight);
  height: 21.25rem;
  width: 21.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
