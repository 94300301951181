.radio {
  --checked-size: 0.4;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--input-radio-checkbox-size);
  height: var(--input-radio-checkbox-size);
  background-color: var(--ion-color-primary-lightest);
  border: 1px solid var(--ion-color-tertiary);
  border-radius: 100%;
  padding: 0;
}

.radio:after {
  content: '';
  display: block;
  width: calc(var(--input-radio-checkbox-size) * var(--checked-size));
  height: calc(var(--input-radio-checkbox-size) * var(--checked-size));
  background-color: transparent;
  border-radius: 100%;
}

.radio input[type='radio'] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
}

.radio:focus-within {
  box-shadow: 0 0 0 3px var(--ion-color-primary-lighter);
  transition: box-shadow 0.2s ease;
}

.disabled {
  border-color: var(--ion-color-primary-lighter);
}

.checked:after {
  background-color: var(--ion-color-tertiary);
}

.checked.disabled:after {
  background-color: var(--ion-color-primary-lighter);
}

.style-TRANSPARENT {
  background-color: transparent;
}

.radioWrapper {
  display: flex;
  gap: var(--default_increment);
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}
