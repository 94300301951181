.tooltip {
    overflow: visible;
    border-radius: var(--border-radius-md) !important;
    --rt-color-dark: var(--ion-color-primary-darker) !important;
    z-index: 1000000000;
}

.tooltip:global(.styles-module_tooltip__mnnfp) {
    font-size: var(--font-size-small);
}

.sidebarTooltip {
    margin-left: 30px;
}