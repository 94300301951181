.optionsFetchingPlaceholder {
  text-align: left;
}

.container {
  display: flex;
  flex-flow: row wrap;
  gap: calc(2 * var(--default_increment));
}

.dropdown {
  flex-basis: 100%;
}

@media (min-width: 540px) {
  .dropdown {
    flex-basis: calc(50% - var(--default_increment));
  }
}

.inputWrapper {
  margin-top: calc(2 * var(--default_increment));
}

.auditDetailsCard {
  position: relative;
  z-index: 10;
}
