.productDetailsImg {
  width: 100%;
  border-radius: var(--border-radius-md);
}

.nutritionWrapper {
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--default_increment));
}

.nutritionWrapper .nutritionTopText {
  margin-top: var(--default_increment);
}

.nutritionWrapper > div {
  padding: 0 8px 0 8px;
}

.imgContent {
  display: flex;
}

.imgContent img,
.imgContent svg {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-md);
}

.menuTitle,
.menuDescription {
  margin: 0;
}

.menuPrice {
  margin: 0;
  margin-top: var(--default_increment);
}

.menuPriceWithoutDiscount {
  text-decoration: line-through;
  color: var(--ion-color-primary);
  margin-left: var(--default_increment);
}

.commentButton {
  display: flex;
  flex-direction: column;
}

h5.nutritionTitle {
  font-weight: bold;
  font-size: var(--font-size-large);
}

.emissionBolded {
  font-weight: bold;
}

.additivesWrapper,
.ingredientsWrapper {
  margin: 0;
}

.modifierSection .allergenText {
  margin-bottom: 1rem;
}

.warningLabel {
  color: var(--ion-color-danger);
  font-size: var(--font-size-small);
  font-weight: 700;
}

.nonWarningLabel {
  font-size: var(--font-size-small);
  font-weight: 500;
}

.warningLabelIcon {
  color: var(--ion-color-danger);
  font-size: var(--font-size-small);
  font-weight: 700;
  float: right;
}

.nonWarningLabelIcon {
  font-size: var(--font-size-small);
  font-weight: 500;
  float: right;
}

.warningAllergenIcon {
  color: var(--ion-color-danger);
  float: left;
}

.nonWarningAllergenIcon {
  color: var(--ion-color-primary);
  float: left;
}

.modifierSection ~ .modifierSection {
  margin-top: calc(2 * var(--default_increment));
}

.cartActions {
  text-align: center;
}

.cartActions .qty_setter {
  margin-left: calc(3 * var(--default_increment));
}

.notificationWrapper {
  margin-bottom: calc(3 * var(--default_increment));
}

.portionSizeWrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}

.portionSize {
  font-weight: bold;
  line-height: normal;
}

.scan {
  margin-top: 1rem;
}

.cartActions > div:not(:only-child) > .dropdown {
  margin-bottom: 1rem;
}

.tagsContainer {
  --spacing-smallest: 0.25rem;
  display: flex;
  gap: var(--spacing-smallest);
  flex-wrap: wrap;
  margin-bottom: var(--default_increment);
}

.modifiersWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--column-spacing);
}

.twoCardsInRow {
  composes: modifiersWrapper;
}

.twoCardsInRow > div {
  flex-grow: 1;
}

.twoCardsInRow:empty {
  display: none;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@media screen and (min-width: 768px) {
  .twoCardsInRow {
    flex-direction: row;
  }
}
