:global(.modal-shadow) {
  pointer-events: none;
}

:global(.modal-wrapper) {
  --border-radius: var(--default_increment);
  --height: auto;
  --width: auto;
  margin: calc(6 * var(--default_increment));
}

/*TODO: for some reason default ionic styles has larger priority than local one */
:global(.modal .ion-page) {
  margin: 0 !important;
  position: initial !important;
  contain: initial !important;
  max-height: calc(100vh - 12 * var(--default_increment));
}

:global(.modal video) {
  object-fit: cover;
  height: var(--default-content-size);
}

.title {
  display: block;
  text-align: center;
  letter-spacing: 0.01em;
  border-bottom: 1px solid var(--ion-color-primary-ultralight);
  padding: calc(3 * var(--default_increment)) calc(8 * var(--default_increment));
}

.titleEmpty {
  height: calc(var(--default_increment) * 7);
}

@media (min-width: 768px) {
  .titleEmpty {
    height: calc(var(--default_increment) * 9);
  }
}

.content {
  padding: calc(3 * var(--default_increment));
  overflow: auto;
}

.footer {
  padding: calc(3 * var(--default_increment));
  border-top: 1px solid var(--ion-color-primary-ultralight);
  display: flex;
  justify-content: center;
  margin-bottom: var(--ion-safe-area-bottom);
}
.footer > * {
  width: var(--default-content-size);
}

.modalClose {
  position: absolute;
  right: 24px;
  top: 28px;
  background-color: transparent;
  color: var(--ion-color-primary-darker);
  outline: 0;
  border: none;
  -webkit-appearance: none;
}

.modalClose:focus svg {
  box-shadow: 0 0 0 3px var(--ion-color-primary-lighter);
  transition: box-shadow 0.2s ease;
}

.leftButton {
  position: absolute;
  left: 24px;
  top: 13px;
  background-color: transparent;
  color: var(--ion-color-primary-darker);
  outline: 0;
  border: none;
}

.leftButtonGoBack {
  position: absolute;
  padding: unset;
  left: calc(3 * var(--default_increment));
  top: calc(3 * var(--default_increment));
  background-color: transparent;
  color: var(--ion-color-primary-darker);
}

.leftButtonGoBack svg {
  width: calc(3 * var(--default_increment));
  height: calc(3 * var(--default_increment));
}

@media (max-width: 768px) {
  :global(.modal-wrapper) {
    --border-radius: var(--default_increment) var(--default_increment) 0 0;
    --max-height: 100%;
    --width: 100%;
    margin: 0;
    margin-top: auto;
  }

  :global(.modal .ion-page) {
    max-height: 100vh;
  }

  .title {
    padding: calc(2 * var(--default_increment)) calc(4 * var(--default_increment));
    padding-right: calc(6 * var(--default_increment));
  }

  .content {
    padding: calc(2 * var(--default_increment));
  }

  .footer {
    padding: calc(2 * var(--default_increment));
  }

  .modalClose {
    right: 16px;
    top: 20px;
  }

  .leftButton {
    left: 0px;
    top: 5px;
  }

  .leftButtonGoBack {
    left: calc(2 * var(--default_increment));
    top: calc(2 * var(--default_increment));
  }
}

@media (max-width: 360px) {
  .content {
    padding: calc(2 * var(--default_increment));
    overflow: auto;
  }

  :global(.modal video) {
    width: 100%;
    margin: auto;
  }
}
