.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--default_increment);
}
.checkboxWrapper {
  display: flex;
  gap: var(--default_increment);
}

.label {
  font-size: var(--font_size_small);
}

.hideEmail {
  display: none;
}
