.mapSwitchIcon {
  stroke: var(--ion-color-primary-darker);
}

:global(.ion-activated) .mapSwitchIcon {
  stroke: var(--ion-color-primary-contrast);
}

.map {
  min-height: 17.12rem;
}

.actionButton {
  width: 100%;
}
