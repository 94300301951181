@keyframes loadSkeleton {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}

.skeleton {
  height: 1rem;
  position: relative;
  display: inline-block;
  border-radius: var(--increment);
}

.skeleton::before {
  content: '';
  border-radius: var(--increment);
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--ion-color-primary-ultralight);
  animation: loadSkeleton 2s infinite;
}

.skeletonSquare {
  aspect-ratio: 1 / 1;
  height: auto;
}

.skeletonCircle,
.skeletonCircle::before {
  border-radius: 50%;
}
