.card {
  background-color: var(--ion-color-white);
  margin: unset;
  box-shadow: var(--default-shadow);
  border-radius: 0.5rem;
  overflow: visible;
  --color: var(--ion-color-primary-darker);
}

.cardHeader {
  padding: calc(2 * var(--default_increment));
  padding-bottom: 0;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftTitle {
  display: flex;
  align-items: center;
  gap: var(--default_increment);
}

.cardIcon {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
}

.cardContent:global(.card-content-md),
.cardContent:global(.card-content-ios) {
  padding: calc(2 * var(--default_increment));
  color: var(--ion-color-primary-darker);
}

/*hack in order to show button shadow when Layout component is used*/
.cardContent:global(.card-content-md):has(ion-toolbar),
.cardContent:global(.card-content-ios):has(ion-toolbar) {
  padding: calc(2 * var(--default_increment) - 5px);
}

/* Fix for Ionic overwriting styles */
.cardContent:global(.card-content-md) :global(.bodyMBold),
.cardContent:global(.card-content-ios) :global(.bodyMBold) {
  font-size: var(--font-size-default);
  font-weight: bold;
  letter-spacing: 0.015em;
  line-height: 1.5;
  color: var(--ion-color-primary-darker);
  margin-top: calc(var(--default_increment) * 2);
  margin-bottom: var(--default_increment);
}
