:root {
  --central-content-max-width-in-px: 752px;
  --default-border: 1px solid var(--ion-color-primary-darker);
  --screen-size-xs: 360px;
}

html {
  font-size: 16px;
}

body {
  background: var(--ion-color-white);
}

body a {
  color: var(--ion-color-primary-darker);
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius-sm);
  background-color: var(--ion-color-primary-light);
}

::-webkit-scrollbar-track {
  background: transparent;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.central-content-container {
  flex-grow: 1;
  padding: 0;
  margin: 0;
}

.central-content {
  width: 100%;
  height: 100%;
  max-width: var(--central-content-max-width-in-px);
  margin: auto;
}

.centered_button_container {
  width: 100%;
  max-width: 368px;
  margin: auto;
}

.centered_button_container ion-button {
  width: 100%;
}

.contrastColor ion-button {
  color: var(--ion-color-primary-contrast);
}

.background_transparent {
  --ion-background-color: transparent !important;
  background-color: transparent !important;
}

.background_neutral {
  --ion-background-color: var(--ion-color-white);
}

.background_primary {
  --ion-background-color: var(--ion-color-primary-darker) !important;
  background-color: var(--ion-color-primary-darker) !important;
}

.background_primary_lightest {
  --ion-background-color: var(--ion-color-primary-lightest) !important;
  background-color: var(--ion-color-primary-lightest) !important;
}

.background_secondary {
  --ion-background-color: var(--ion-color-secondary) !important;
  background-color: var(--ion-color-secondary) !important;
}

.background_inactive {
  --ion-background-color: var(--ion-color-primary-lightest) !important;
  background-color: var(--ion-color-primary-lightest) !important;
}

.background_white {
  --ion-background-color: var(--ion-color-white) !important;
  background-color: var(--ion-color-white) !important;
}

.colorPrimaryBlueLight {
  color: var(--ion-color-primary-light);
}

ion-radio-group {
  display: flex;
  min-width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 1em 0;
}

select {
  padding: var(--default_increment);
  border: var(--default-border);
}

h1,
h2,
h3,
h4 {
  text-align: left;
}

h2 {
  color: var(--ion-color-primary-darker);
}

h3 {
  font-weight: bold;
}

/* Reset css */
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

/** Font styles - Headline **/
.headlineL {
  font-size: var(--font-size-xxxxlarge);
  font-weight: bold;
  line-height: var(--line-height-xlarge);
  color: var(--ion-color-primary-darker);
}

.headlineS {
  font-size: var(--font-size-large);
  font-weight: bold;
  line-height: var(--line-height-small);
  color: var(--ion-color-primary-darker);
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .headlineS {
    font-size: var(--font-size-xlarge);
    font-weight: bold;
    line-height: var(--line-height-medium);
    color: var(--ion-color-primary-darker);
  }
}

@media screen and (min-width: 1366px) {
  .headlineS {
    font-size: var(--font-size-xxxlarge);
    font-weight: bold;
    line-height: var(--line-height-large);
    color: var(--ion-color-primary-darker);
  }
}

.headlineXS {
  font-size: var(--font-size-large);
  font-weight: bold;
  line-height: var(--line-height-small);
  color: var(--ion-color-primary-darker);
}

/** Font styles - Subtitle **/
.subtitleS {
  font-size: var(--font-size-medium);
  font-weight: bold;
  line-height: var(--line-height-xsmall);
  color: var(--ion-color-primary-darker);
}

/** Font styles - Body **/
.bodyMDefault {
  font-size: var(--font-size-default);
  font-weight: normal;
  line-height: var(--line-height-xsmall);
  color: var(--ion-color-primary-darker);
}

.bodyMBold {
  font-size: var(--font-size-default);
  font-weight: bold;
  line-height: var(--line-height-xsmall);
  color: var(--ion-color-primary-darker);
}

.bodyMLink {
  font-size: var(--font-size-default);
  font-weight: normal;
  line-height: var(--line-height-xsmall);
  color: var(--ion-color-primary-darker);
  text-decoration-line: underline;
}

.bodySDefault {
  font-size: var(--font-size-small);
  font-weight: normal;
  line-height: var(--line-height-xsmall);
}

.bodySBold {
  font-size: var(--font-size-small);
  font-weight: bold;
  line-height: var(--line-height-xsmall);
}

.bodySLink {
  font-size: var(--font-size-small);
  font-weight: normal;
  line-height: var(--line-height-xsmall);
  color: var(--ion-color-primary-darker);
  text-decoration-line: underline;
}

.buttonDefault {
  font-size: var(--font-size-small);
  font-weight: 600;
  line-height: var(--line-height-xsmall);
  color: var(--ion-color-primary-darker);
}

.captionDefault {
  font-size: var(--font-size-xsmall) !important;
  font-weight: normal;
  line-height: var(--line-height-xsmall);
  color: var(--ion-color-primary-darker);
}

.underlinedItalicText {
  font-style: italic;
  text-decoration-line: underline;
  color: var(--ion-color-primary);
}

.italicText {
  font-style: italic;
  color: var(--ion-color-primary);
}

.underlinedText {
  text-decoration-line: underline;
  color: var(--ion-color-primary);
}

.textPrimary {
  color: var(--ion-color-primary-darker);
}

label {
  color: var(--ion-color-primary-darker);
  font-size: 1em;
}

label.forCheckboxRight {
  padding-right: 0.62rem;
}

label.forCheckboxLeft {
  padding-left: 0.62rem;
}

.checkboxWrapper {
  display: flex;
  align-items: flex-start;
}

.highlight {
  color: var(--ion-color-primary-darker);
  font-weight: bold;
}

.warning {
  color: var(--ion-color-warning);
  font-weight: bold;
}

.comment {
  color: var(--ion-color-primary-light);
  font-size: 1em;
}

.ellipsis {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.default_border {
  border: var(--default-border);
}

.modal .ion-page {
  margin: 1em;
}

.modal_title {
  text-align: center;
}

.modal_close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.link {
  text-decoration: underline;
}

.service_icon {
  width: calc(var(--default_increment) * 2.5);
  height: calc(var(--default_increment) * 2.5);
  color: var(--ion-color-primary-contrast);
}

.checkboxAsNoteBelowSth {
  margin: 0.5em 0;
  display: flex;
  align-items: center;
}

.checkboxAsNoteBelowSth > * {
  margin-right: 0.5em;
  font-style: italic;
}

.formElementLabel {
  font-size: var(--font-size-small);
  font-weight: bold;
  color: var(--ion-color-primary-darker);
  display: inline-block;
  margin-bottom: var(--default_increment);
}

/*images*/
#main_image_container {
  width: 100%;
  text-align: center;
}

#main_image_container img {
  max-height: 50vmin;
}

/*illustrations*/
.illustration_primary_default {
  fill: var(--ion-color-primary);
  stroke-width: 0px;
}
.illustration_primary {
  fill: var(--ion-color-primary-darker);
  stroke-width: 0px;
}

.illustration_primary-light {
  fill: var(--ion-color-primary-light);
  stroke-width: 0px;
}

.illustration_primary-lighter {
  fill: var(--ion-color-primary-lighter);
  stroke-width: 0px;
}

.illustration_primary-lightest {
  fill: var(--ion-color-primary-lightest);
  stroke-width: 0px;
}

.illustration_primary-ultralight {
  fill: var(--ion-color-primary-ultralight);
  stroke-width: 0px;
}

.illustration_secondary {
  fill: var(--ion-color-secondary);
  stroke-width: 0px;
}

.illustration_tertiary {
  fill: var(--ion-color-tertiary);
  stroke-width: 0px;
}

.illustration_background {
  fill: var(--ion-color-primary-ultralight);
  stroke-width: 0px;
}

.illustration_skin {
  fill: var(--ion-color-skin);
  stroke-width: 0px;
}

#Details_details-container,
#SiteDetails_details-container {
  padding-bottom: 15px;
}
/*stroke*/
.stroke_primary-darker {
  stroke: var(--ion-color-primary-darker);
}

/*icons*/
svg.icon_primary * {
  stroke: var(--ion-color-primary);
}

svg.icon_primary_filled path {
  fill: var(--ion-color-primary);
}

svg.icon_success * {
  stroke: var(--ion-color-success);
}

svg.icon_success_filled path {
  fill: var(--ion-color-success);
}

svg.icon_danger * {
  stroke: var(--ion-color-danger);
}

svg.icon_danger_filled path {
  fill: var(--ion-color-danger);
}

svg.icon_inactive * {
  stroke: var(--ion-color-medium);
}

svg.icon_inactive_filled path {
  fill: var(--ion-color-medium);
}

/* colors */
.secondary {
  fill: var(--ion-color-secondary-contrast) !important;
}

.tertiary {
  fill: var(--ion-color-tertiary) !important;
}

.clickable {
  cursor: pointer;
}

/* TODO once ion lists are removed as wrappers, remove this */
ion-list {
  contain: none;
}

.centered_loader {
  flex-grow: 1;
}

.card ion-card {
  margin-top: unset;
}

/** Select Modal **/
ion-alert.select-alert {
  --width: 50vw !important;
  --max-width: 66vw !important;
}
ion-alert.select-alert .select-interface-option .alert-radio-label,
ion-alert.select-alert .select-interface-option .alert-checkbox-label {
  white-space: normal !important;
}
ion-select::part(icon) {
  display: none !important;
}

/** Buttons in IonAlert **/
.alert-button.sc-ion-alert-md {
  color: black;
}

.card-content-md h3,
.card-content-md p,
.paragraph {
  margin: unset;
}

.paragraph {
  margin: unset;
}
