.infoMessage {
  text-align: center;
  padding-top: calc(var(--default_increment) * 2);
  padding-bottom: calc(var(--default_increment) * 2);
  color: var(--ion-color-primary-darker);
}

.title {
  composes: bodySBold from global;
}

.icon {
  margin-bottom: var(--default_increment);
}

.icon svg {
  width: 1.25rem;
}

.buttonContainer {
  margin-top: calc(var(--default_increment) * 2);
}
