.checkboxWrapper {
  display: flex;
  gap: var(--default_increment);
}

.checkbox.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: var(--input-radio-checkbox-size);
  height: var(--input-radio-checkbox-size);
  background-color: var(--ion-color-primary-ultralight);
  border: 1px solid var(--ion-color-tertiary);
  border-radius: var(--border-radius-sm);
}

.checkbox.disabled {
  pointer-events: none;
  border-color: var(--ion-color-primary-lighter);
  background: var(--ion-color-primary-lightest);
}

.checkbox:focus-within {
  box-shadow: 0 0 0 3px var(--ion-color-primary-lighter);
  transition: box-shadow 0.2s ease;
}

.checkbox input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  cursor: pointer;
}

.icon {
  width: 1.125rem;
  opacity: 0;
}

.checked {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-primary-darker);
}

.checked .icon {
  opacity: 1;
}

.checked.disabled {
  color: var(--ion-color-primary-lightest);
  background-color: var(--ion-color-primary-lighter);
}
