.layoutWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.row {
  width: var(--default-content-size);
  align-self: center;
  color: var(--ion-color-primary-darker);
  composes: bodySDefault from global;
}

.imageRow {
  width: var(--default-content-size);
  height: 13rem;
  top: 0;
  display: flex;
  justify-content: center;
}
