.promotionItemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.promotionItemInfoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin-bottom: var(--default_increment);
}

@media screen and (max-width: 540px) {
  .promotionItemInfoContainer {
    width: 65%;
  }
}

@media screen and (max-width: 440px) {
  .promotionItemInfoContainer {
    width: 55%;
  }
}

.promotionItemTitle {
  width: 100%;
  flex-grow: 1;
  font-weight: bold;
}

.promotionItemDescription {
  width: 80%;
  flex-grow: 1;
}

.promotionItemAction {
  width: 20%;
  flex-grow: 1;
  align-items: center;
  justify-content: right;
  display: flex;
  font-weight: bold;
  text-decoration: underline;
}

.promotionItemActivated {
  color: #115249;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
}
