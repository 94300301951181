.Toastify__toast-theme--light.Toastify__toast--success {
  background-color: var(--ion-color-success-light);
  color: var(--ion-color-success);
}

.Toastify__progress-bar--success {
  background-color: var(--ion-color-success);
}

.Toastify__toast-theme--light.Toastify__toast--error {
  background-color: var(--ion-color-danger-light);
  color: var(--ion-color-danger);
}

.Toastify__progress-bar--error {
  background-color: var(--ion-color-danger);
}

.Toastify__toast-theme--light.Toastify__toast--info {
  background-color: var(--ion-color-primary-ultralight);
  color: var(--ion-color-primary);
}

.Toastify__toast-theme--light.Toastify__toast--info
  > .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter
  svg {
  background: var(--ion-color-primary);
}

.Toastify__progress-bar--info {
  background: var(--ion-color-primary);
}
