.TitleBar {
  --ion-background-color: var(--ion-color-primary-contrast);
  padding: 6.5px var(--default_increment);
  border-bottom: 1px solid var(--ion-color-primary-lighter) !important;
}

.TitleBar_Toolbar {
  --border-color: var(--ion-color-primary-lighter) !important;
}

.TitleBar * {
  color: var(--ion-color-primary-darker);
  --background: var(--ion-color-primary-contrast);
  --min-height: 2.875rem;
}

@media screen and (max-width: 720px) {
  .TitleBar * {
    --min-height: 2.375rem;
  }
}
