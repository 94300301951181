.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--default_increment) 0;
  cursor: pointer;
}

.option:first-child {
  padding-top: 0;
}

.option:last-child {
  padding-bottom: 0;
}

.option label {
  cursor: pointer;
}

.option + .option {
  border-top: 1px solid var(--ion-color-primary-lighter);
}

.labelWrapper {
  width: 100%;
}
