.button {
  width: 100%;
}

.mainColumn {
  flex: unset;
  row-gap: unset;
}

@media screen and (max-width: 1023.99px) {
  .containerFlex {
    flex-direction: column-reverse;
    flex-grow: 0;
  }
}

@media screen and (max-width: 1023.99px) {
  .illustration {
    display: none;
  }
}
