.cartButtonContainer {
  display: flex;
}

.quantitySetterInlineContainer {
  flex-shrink: 2;
  margin-right: calc(2 * var(--default_increment));
}

.popupNative {
  padding-bottom: 200px;
}
