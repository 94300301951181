.mainContainer {
  display: flex;
  flex-direction: column;

  --column-width: 30rem;
  width: var(--column-width);
  --spacing: calc(2 * var(--default_increment));
  row-gap: var(--spacing);
}

.goToHomeButton {
  margin: 0 calc(2 * var(--default_increment));
}

@media screen and (max-width: 767.99px) {
  .mainColumn {
    flex: unset;
  }

  .mainContainer {
    width: 100%;
  }
}

.title {
  align-self: center;
}
