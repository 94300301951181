.notification {
  padding: calc(var(--increment) * 4);
  padding-top: calc(var(--increment) * 3);
  padding-bottom: 0;
  border-radius: var(--border-radius-md);
  color: var(--color);
  overflow: hidden;
}

.topContent {
  display: flex;
  align-items: flex-start;
  gap: var(--default_increment);
}

.notification + :global(.tiles_list) {
  margin-top: var(--column-spacing);
}

/*
* Styles
*/
.info {
  background-color: var(--ion-color-primary-darker);
  --color: var(--ion-color-primary-contrast);
  composes: bodySDefault from global;
}

.error {
  background-color: var(--ion-color-danger-light);
  --color: var(--ion-color-danger);
  composes: bodySDefault from global;
}

.success {
  background-color: var(--ion-color-success-light);
  --color: var(--ion-color-success);
  composes: bodySDefault from global;
}

.message {
  background-color: var(--ion-color-primary-contrast);
  --color: var(--ion-color-primary-darker);
  composes: bodyMDefault from global;
  box-shadow: var(--default-shadow);
  padding: 0;
}

.details {
  background-color: var(--ion-color-primary-ultralight);
  --color: var(--ion-color-primary);
  composes: bodySDefault from global;
}

.message .contentWrapper {
  align-self: center;
}

.message .contentWrapper .textWrapper {
  padding: calc(2 * var(--default_increment));
  margin-bottom: unset;
  gap: var(--default_increment);
}

.actions {
  margin-left: auto;
}

.contentWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.actions {
  margin-left: auto;
  margin-bottom: calc(var(--increment) * 3);
}

.actions :global(.button):not(:nth-last-child(1)) > :global(.button) {
  margin-right: var(--default_increment);
}

.textWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: calc(var(--increment) * 3);
}

.content {
  margin: 0;
  flex-shrink: 0;
}

.icon {
  line-height: 1;
}

.imgWrapper {
  flex-shrink: 0;
  height: 7rem;
  display: flex;
}

.imgWrapper svg {
  width: 7rem;
  height: 7rem;
}

.collapsableContent {
  flex-wrap: nowrap;
  align-items: unset;
  --column-spacing: var(--default_increment);
}

.collapsableWrapper {
  padding-top: calc(var(--increment) * 2);
}

.collapsableContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: var(--default_increment);
}

.collapsableItem {
  width: 100%;
  flex-grow: 1;
  padding-bottom: var(--increment);
  padding-top: var(--increment);
  padding-left: var(--default_increment);
  padding-right: var(--default_increment);
}

.collapsableItem:after {
  content: '';
  border-bottom: 1px solid var(--ion-color-primary-lighter);
  width: 100%;
  display: block;
  margin: 0 auto;
}

.collapsableItem:last-child:after {
  border-bottom: none;
}

@media (min-width: 720px) {
  .collapsableItem {
    width: 50%;
  }

  .collapsableLastRowItem:after {
    border-bottom: none;
  }

  .collapsableItem:nth-child(odd) {
    border-right: 1px solid var(--ion-color-primary-lighter);
  }

  .collapsableItem:nth-child(odd):last-child {
    border-right: none;
  }
}
