.noItemsPlaceholder {
  color: var(--ion-color-primary-darker);
  border-radius: var(--default_increment);
  height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 2px dashed var(--ion-color-primary-ultralight);
  font-size: var(--font-size-small);
  font-weight: 700;
  padding: var(--default_increment);
  cursor: pointer;
}

.noItemsPlaceholder p,
.noItemsPlaceholder svg {
  color: var(--ion-color-primary-lightest);
  margin: 0;
  text-align: center;
}
