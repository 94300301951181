.searchContainer {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
}

.searchBarRight {
  display: flex;
  align-items: center;
  gap: var(--default_increment);
  width: 100%;
  overflow: hidden;
}

.searchBarRight.hidden {
  display: none;
}

.searchContainer .withBorder {
  border-left: 1px solid var(--ion-color-primary-ultralight);
  padding-left: calc(2 * var(--default_increment));
  margin-top: calc(-2 * var(--default_increment));
  margin-bottom: calc(-2 * var(--default_increment));
}

.horizontalWrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: calc(2 * var(--default_increment));
}

.searchInputWrapper {
  flex: 1 1;
  flex-grow: 5;
}


.searchInputWrapper.hidden {
  display: none;
}

.search {
  flex: 1;
}

.icon {
  color: var(--ion-color-primary);
}

.searchButton.hidden {
  display: none;
}

@media screen and (min-width: 768px) {
  .searchBarRight {
    width: unset;
    max-width: 50%;
  }
  .searchBarRight.hidden {
    display: initial;
  }

  .searchInputWrapper.hidden {
    display: initial;
  }

  .searchButton {
    display: none;
  }

  .searchContainer .withBorder {
    border-left: unset;
    padding-left: unset;
    margin-top: unset;
    margin-bottom: unset;
  }
}
