.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mapWrapper {
  aspect-ratio: 4/3;
  width: 100%;
}

.tileWrapper {
  flex: 0 0 35%;
}

@media screen and (max-height: 1023.99px) {
  .mapWrapper {
    max-height: 25rem;
  }
}

.image {
  padding: var(--default_increment);
}
