.container {
  gap: unset;
}

.complementaryColumn {
  width: unset;
}

.mainColumn {
  max-width: unset;
}

.buttons {
  display: flex !important;
  gap: 0.75rem;
}

.buttons > div > ion-button {
  width: 2.25rem;
  height: 2.25rem;
  min-height: unset;
}

.helpdeskSearchBar > div > ion-card > ion-card-content > div > div > div {
  width: unset;
}

.loading {
  animation: rotation 500ms infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
