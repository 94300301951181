.promotionCell {
  width: 100%;
  flex-grow: 1;
  padding-bottom: var(--increment);
  padding-top: var(--increment);
  padding-left: var(--default_increment);
  padding-right: var(--default_increment);
}

.promotionCell:after {
  content: '';
  border-bottom: 1px solid var(--ion-color-primary-lighter);
  width: 100%;
  display: block;
  margin: 0 auto;
}

.promotionCell:last-child:after {
  border-bottom: none;
}
