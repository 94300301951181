.subheader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
  gap: calc(2 * var(--default_increment));
}

.group {
  display: flex;
  flex-direction: row;
  padding: 0;
  gap: var(--default_increment);
  align-items: center;
}

.notPaid > h6,
.notPaid > svg {
  color: var(--icon-color-green-red);
}

.title {
  padding-bottom: var(--default_increment);
}

@media screen and (max-width: 1023.99px) {
  .containerFlex {
    flex-direction: column-reverse;
    flex-grow: 0;
  }
}
