/* https://fkhadra.github.io/react-toastify/how-to-style/ */

/* iPhone 14 pro max */
@media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
  body .Toastify__toast {
    padding-top: 60px;
  }
}

/* iPhone 14 pro */
@media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-min-device-pixel-ratio: 3) {
  body .Toastify__toast {
    padding-top: 60px;
  }
}

/* iPhone 14 */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-min-device-pixel-ratio: 3) {
  body .Toastify__toast {
    padding-top: 60px;
  }
}

/* iPhone 13 Pro Max */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  body .Toastify__toast {
    padding-top: 60px;
  }
}

/* iPhones X, Xs, XR and 11 */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  body .Toastify__toast {
    padding-top: 50px;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  body .Toastify__toast {
    padding-top: 50px;
  }
}

/* iPhone SE */
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  body .Toastify__toast {
    padding-top: 30px;
  }
}
