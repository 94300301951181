/* Top Navigation Bar */
.container {
  margin: auto;
  background-color: var(--ion-color-primary-contrast);
  width: var(--default-content-size);
}
@media (max-width: 768px) {
  .container {
    width: auto;
  }
}
:global(.react-calendar__navigation) {
  background: var(--ion-color-primary-lightest);
  padding: 12px;
}
:global(.react-calendar__navigation) button {
  color: var(--ion-color-primary-darker);
  background: none;
  font-size: var(--font-size-small);
  line-height: 1.714;
  font-weight: normal;
  letter-spacing: 0.02em;
}
:global(.react-calendar__navigation__label__labelText--from) {
  font-weight: bold;
  color: var(--ion-color-primary-darker);
  text-transform: capitalize;
  border: 1px solid var(--ion-color-primary-darker);
  padding: 2px 12px;
  border-radius: var(--default_increment);
  box-sizing: border-box;
}
:global(.react-calendar__navigation__arrow) {
  font-size: 1.69rem;
  padding: 0;
  color: var(--ion-color-primary-darker);
}
:global(.react-calendar__navigation__arrow):disabled {
  cursor: not-allowed;
  color: var(--ion-color-primary-lighter);
}

:global(.react-calendar__month-view__days__day):disabled abbr {
  color: var(--ion-color-primary-lighter);
  cursor: not-allowed;
}
:global(.react-calendar__month-view__days__day):disabled:hover abbr {
  background-color: transparent;
  font-weight: normal;
}
/* End Top Navigation Bar */

:global(.react-calendar__month-view__days),
:global(.react-calendar__year-view__months),
:global(.react-calendar__decade-view__years) {
  padding: 12px;
  border: 2px solid var(--ion-color-primary-lightest);
}

/* Day View */
:global(.react-calendar__tile) {
  margin: 0;
}

:global(.react-calendar__tile) abbr {
  font-size: var(--font-size-default);
  line-height: 24px;
}

:global(.react-calendar__month-view__days__day) {
  background: none;
  padding: 0px;
}

:global(.react-calendar__month-view__days__day) abbr {
  width: 40px;
  height: 40px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-primary-darker);
}

/* Square tiles  : This is needed to have square tiles (height = width) */
:global(.react-calendar__month-view__days__day):before {
  content: '';
  float: left;
  padding-top: 100%;
}

:global(.react-calendar__month-view__days__day) {
  position: relative;
  font-weight: normal;
}

:global(.react-calendar__tile--active) abbr {
  border-radius: 100px;
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast-text);
  font-weight: bold;
}

:global(.react-calendar__month-view__days__day.react-calendar__tile--now) abbr {
  border-radius: 100px;
  background-color: var(--ion-color-primary-lightest);
  font-weight: bold;
  color: var(--ion-color-primary-darker);
}

:global(.react-calendar__month-view__days__day.react-calendar__tile--now.react-calendar__tile--active)
  abbr {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast-text);
}

:global(.react-calendar__month-view__days__day) abbr:hover {
  border-radius: 40px;
  font-weight: bold;
  background-color: var(--ion-color-primary-ultralight);
}

:global(.react-calendar__month-view__days__day.highlighted) abbr:after {
  content: '';
  position: absolute;
  top: calc(50% + 10px);
  background: var(--ion-color-tertiary);
  border-radius: 100px;
  height: 4px;
  width: 4px;
  z-index: 100;
}

/* Header tiles */
:global(.react-calendar__month-view__weekdays) {
  background: var(--ion-color-primary-lightest);
  padding-left: 12px;
  padding-right: 12px;
}

:global(.react-calendar__month-view__weekdays__weekday) {
  text-align: center;
  padding-bottom: 12px;
}
:global(.react-calendar__month-view__weekdays__weekday) abbr {
  color: var(--ion-color-primary-darker);
  text-decoration: none;
  font-weight: bold;
}
/* End of header tiles ( Name of the day */

/* Month View */
:global(.react-calendar__year-view__months__month),
:global(.react-calendar__decade-view__years__year) {
  height: 48px;
  border-radius: var(--default_increment);
  background: none;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-primary-darker);
  text-transform: capitalize;
  border: 4px solid var(--ion-color-primary-contrast);
  padding: 0;
  font-size: var(--font-size-default);
}

:global(.react-calendar__year-view__months__month.react-calendar__tile--now),
:global(.react-calendar__decade-view__years__year.react-calendar__tile--now) {
  background-color: var(--ion-color-primary-lightest);
  font-weight: bold;
  color: var(--ion-color-primary-darker);
}

:global(.react-calendar__year-view__months__month):hover,
:global(.react-calendar__decade-view__years__year):hover {
  border-radius: var(--default_increment);
  font-weight: bold;
  background-color: var(--ion-color-primary-ultralight);
}

:global(.react-calendar__year-view__months__month):disabled abbr,
:global(.react-calendar__decade-view__years__year):disabled {
  color: var(--ion-color-primary-lighter);
}

:global(.react-calendar__year-view__months__month):disabled:hover,
:global(.react-calendar__decade-view__years__year):disabled:hover {
  background-color: transparent;
  font-weight: normal;
}

:global(.react-calendar__year-view__months__month.react-calendar__tile--active),
:global(.react-calendar__year-view__months__month.react-calendar__tile--hasActive),
:global(.react-calendar__decade-view__years__year.react-calendar__tile--hasActive) {
  background-color: var(--ion-color-tertiary);
  font-weight: bold;
  color: var(--ion-color-primary-darker);
}

/* Year View */
