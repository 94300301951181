.TitleBarTwoIconsLink {
  position: relative;
  height: calc(3 * var(--default_increment));
  margin-left: 0;
  display: flex;
  align-items: center;
  font-size: calc(1.75 * var(--default_increment));
}

.TitleBarTwoIconsLink > a {
  display: flex;
  align-items: center;
}

.text {
  margin-left: var(--default_increment);
  margin-right: var(--default_increment);
}

@media (max-width: 600px) {
  .titleText {
    max-width: 80%;
  }
}

@media (max-width: 500px) {
  .TitleBarTwoIconsLink {
    overflow: inherit;
    display: -webkit-inline-box;
    max-width: 20rem;
    max-height: 3rem;
    vertical-align: middle;
  }

  .titleText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 80%;
  }
}

@media (max-width: 350px) {
  .titleText {
    max-width: 9rem;
  }
}
