.cardContent {
  display: flex;
  flex-direction: column;
  gap: calc(var(--default_increment) * 2);
}

.actionCardContent {
  display: flex;
  flex-direction: row;
  gap: var(--default_increment);
}

.rowReverse {
  flex-direction: row-reverse;
}

.actionCardText {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-align: left;
}

.actionCardPicture {
  width: calc(var(--default_increment) * 14);
}
