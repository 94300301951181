.continueJourneyQRCode {
  margin-top: calc(4 * var(--default_increment));
  margin-bottom: calc(4 * var(--default_increment));
  display: flex;
  width: 100%;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(2 * var(--default_increment)) 0;
  overflow: hidden;
}
