.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image {
  margin-right: var(--default_increment);
}

.title {
  height: 1.175rem;
}

.contentArea {
  margin: 0 var(--default_increment) 0 var(--default_increment);
  display: flex;
  flex-direction: column;
  flex: 1;
}

.dummyContent {
  height: 0.9rem;
  margin-bottom: var(--default_increment);
}

.socialButton {
  height: 1rem;
}
