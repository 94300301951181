#onetrust-reject-all-handler {
  background-color: var(--ion-color-tertiary) !important;
  border-radius: 4px !important;
  color: var(--ion-color-primary-darker) !important;
  border: none !important;
}

#onetrust-pc-btn-handler {
  background-color: var(--ion-color-tertiary) !important;
  border-radius: 4px !important;
  color: var(--ion-color-primary-darker) !important;
  border: none !important;
}

#onetrust-accept-btn-handler {
  border-radius: 4px !important;
  background-color: var(--ion-color-tertiary) !important;
  color: var(--ion-color-primary-darker) !important;
  border: none !important;
}

#accept-recommended-btn-handler {
  border-radius: 4px !important;
  background-color: var(--ion-color-tertiary-tint) !important;
  color: var(--ion-color-primary-darker) !important;
  border: none !important;
}

.ot-pc-refuse-all-handler {
  border-radius: 4px !important;
  background-color: var(--ion-color-tertiary-tint) !important;
  color: var(--ion-color-primary-darker) !important;
  border: none !important;
}

.save-preference-btn-handler.onetrust-close-btn-handler {
  border-radius: 4px !important;
  background-color: var(--ion-color-tertiary-tint) !important;
  color: var(--ion-color-primary-darker) !important;
  border: none !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
  background-color: var(--ion-color-tertiary-tint) !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob:before {
  background-color: var(--default-background-color) !important;
  border-color: var(--ion-color-tertiary-tint) !important;
}

#onetrust-pc-sdk .ot-switch-nob:before {
  background-color: var(--ion-color-primary-contrast) !important;
}

#onetrust-pc-sdk .ot-switch-nob {
  background-color: var(--ion-color-primary-lighter) !important;
}
