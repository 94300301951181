.table {
  width: 100%;
}

.thead {
  position: sticky;
  top: 0rem;
  left: 0rem;
  background: white;
  text-align: left;
  border-bottom: 1px solid var(--ion-color-primary-lighter);
}

.thead > .row > th:first-of-type {
  padding-left: 0.25rem;
}
.thead > .row > th:last-of-type {
  padding-left: 0.25rem;
}

.th {
  width: 12.125rem;
}

.tbody > tr:not(:last-child) {
  border-bottom: 1px solid var(--ion-color-primary-ultralight);
}

.row {
  height: 3.5rem;
}

.cell:first-of-type {
  font-weight: bold;
  padding-left: 0.25rem;
}

.cell {
  padding: 0.5rem 1rem 0.5rem 0;
  color: var(--ion-color-primary);
  vertical-align: top;
}

.threeLinesEllipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.25rem * 3);
  white-space: normal;
}

.skeletonRow {
  padding: 0 0.25rem;
}

.skeleton {
  height: 1.25rem;
  margin: 0.5rem 1rem 0.5rem 0;
}

.clickableRow {
  cursor: pointer;
}
.clickableRow:hover {
  background-color: var(--ion-color-primary-ultralight);
}

.scrollTrigger {
  width: 1px;
  height: 1px;
  visibility: hidden;
}

@media (max-width: 768px) {
  .thead {
    display: none;
  }
  .row {
    display: block;
    padding: 0.75rem 0.25rem;
    height: unset;
  }

  .cell {
    display: block;
    text-align: left;
    padding: unset;
  }

  .cell:first-of-type {
    padding-left: unset;
    padding-bottom: 0.25rem;
    color: var(--ion-color-primary-darker);
    font-size: 1rem;
  }

  .skeletonRow {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.75rem;
  }

  .skeletonCell {
    height: 1rem;
    margin: unset;
    width: unset;
    width: 70%;
    min-width: 17rem;
    margin-bottom: 0.25rem;
  }

  .skeleton {
    height: 1rem;
  }

  .skeletonRow > .skeletonCell:nth-of-type(1) .skeleton {
    width: 70% !important;
  }

  .skeletonRow > .skeletonCell:nth-of-type(4) .skeleton {
    width: 65% !important;
  }
}
