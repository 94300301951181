.priceWithoutDiscounts {
  text-decoration: line-through;
  justify-content: left;
}

.pricesContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: var(--default_increment);
  justify-content: left;
  width: 100%;
}
