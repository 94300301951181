.divider {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: var(--column-spacing);
  margin-top: var(--column-spacing);
  color: var(--ion-color-primary-darker);
  composes: bodySBold from global;
}

.divider:before,
.divider:after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: var(--ion-color-primary-lighter);
}

.title {
  display: inline-flex;
  margin-right: var(--default_increment);
  margin-left: var(--default_increment);
}
