.buttonWrapper {
    border-bottom: 1px solid var(--ion-color-primary-lighter);
   padding-bottom: calc(2 * var(--default_increment));
}

.buttonSpace {
    margin-right: var(--default_increment);
}

.formWrapper {
    padding-top: calc(2 * var(--default_increment));
}

.noPadding {
    padding: 0;
}

.noMarginTop {
    margin-top: 0 !important;
}

.guestFirstNameInputWrapper{
    width: 100%;
    float: none;
    padding: 0;
    margin-bottom:  calc(2 * var(--default_increment));
}

.guestLastNameInputWrapper{
    width: 100%;
    float: none;
    padding: 0;
}

@media screen and (min-width: 720px) {
    .guestFirstNameInputWrapper{
        width: 50%;
        padding-right: calc( var(--default_increment));
        float: left;
        margin-bottom: 0;
    }

    .guestLastNameInputWrapper{
        padding-left: calc( var(--default_increment));
        width: 50%;
        float: left;
    }

    .clear {
        clear: both;
        float: none;
    }


}

