.seeMoreButton {
  display: flex;
  padding: 0.5rem 0.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.contentContainer {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  gap: 1.25rem;
  flex: 1 0 0;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.searchResultButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
