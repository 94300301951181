.wrapper {
  --icon-size: calc(var(--increment) * 7);
  --icon-offset: 5.5;

  display: flex;
  align-items: center;
  position: relative;
}

.reaction svg {
  width: var(--icon-size);
  height: var(--icon-size);
  border: calc(var(--icon-size) / 14) solid var(--ion-color-primary-contrast);
  margin-left: calc(-1 * (var(--icon-size) / var(--icon-offset)));
  border-radius: 100%;
}

.total {
  padding-left: var(--default_increment);
  font-weight: 600;
}
