.filterWrapper {
  display: inline-flex;
  flex-direction: column;
  color: var(--ion-color-primary-darker);
  width: 100%; 
}

.reversed {
  flex-direction: column-reverse;
}

ion-modal .filterWrapper {
  display: block;
}

.filterContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: var(--default_increment);
  flex: 1 1;
}

.allFilters {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--column-spacing);
  row-gap: calc(2 * var(--default_increment));
}

.filterLabel {
  margin-bottom: var(--default_increment);
  margin-top: 0;
}

.filterTitle {
  margin-bottom: var(--default_increment);
}

.filterText {
  color: var(--ion-color-primary-darker);
  height: 1.875rem;
  text-align: left;
}

.customSelect,
.multiSelectWrapper {
  margin: 0.267rem 0;
}

.default_select {
  width: 100%;
  text-align: left;
  color: var(--ion-color-primary-darker);
  border-radius: var(--default_increment);
  --padding-end: var(--default_increment);
}

.default_select::part(icon) {
  content: '';
}

.default_select::part(text) {
  background-position: right;
  background-repeat: no-repeat;
}

.customSelectWidth {
  width: 9.375rem;
}

.customSelectHeight {
  height: 2.5rem;
}

.default_select {
  border: var(--default-border);
  --padding-end: calc(5 * var(--default_increment));
  min-width: 100%;
}

.actionsWrapper {
  background-color: var(--ion-color-primary-contrast);
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: var(--default_increment);
  z-index: 100;
}

.modalContent {
  max-height: 60vh;
}

.invisible {
  display: none;
}

.selectWrapper {
  display: flex;
  align-items: center;
  color: var(--ion-color-primary-darker);
  position: relative;
  flex-grow: 1;
}

.selectWrapperModal {
  width: 100%;
}

.chevron {
  width: calc(3 * var(--default_increment));
  right: var(--default_increment);
  position: absolute;
}

ion-select::part(icon) {
  display: none !important;
}

