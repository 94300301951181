.pie {
  --border: 0.45rem; /*border of doughnut*/
  --not-completed-color: var(--ion-color-primary-ultralight);
  --completed-color: var(--ion-color-secondary);
  --width: 4.5rem; /*width of the square container*/

  min-width: var(--width);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-size: var(--font-size-large);
  font-weight: 600;
  font-family: var(--ion-font-family);
}

.pie:before {
  content: '';
  position: absolute;
  border-radius: 50%;
  inset: 0;
  background: conic-gradient(
    var(--not-completed-color) calc(var(--empty-percentage) * 1%),
    var(--completed-color) 0
  );
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--border)),
    #000 calc(100% - var(--border))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--border)),
    #000 calc(100% - var(--border))
  );
}
