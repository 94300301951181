.service_request_status_open {
  color: var(--ServiceRequest-status-open);
}

.service_request_status_pending {
  color: var(--ServiceRequest-status-pending);
}

.service_request_status_closed {
  color: var(--ServiceRequest-status-closed);
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--default_increment);
}
