.topContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.connectedStatus {
  display: flex;
  align-items: center;
}

.connectedStatusIcon {
  width: calc(var(--default_increment) * 1.5);
  height: calc(var(--default_increment) * 1.5);
  margin-right: calc(var(--default_increment) * 1.5);
  border-radius: 100%;
}

.statusConnected {
  background-color: var(--icon-color-green-medium);
}

.statusDisconnected {
  background-color: var(--ion-color-state-red-dark);
}

.syncButton {
  display: flex;
  align-items: center;
  gap: var(--default_increment);
}

.errorText {
  line-height: 1.25rem;
}

.loading {
  animation: rotation 500ms infinite linear;
}

.infoMessage {
  border: 2px dashed var(--ion-color-primary-lighter);
  border-radius: 8px;
}

.icon svg {
  width: unset !important;
}

.title {
  composes: bodyMBold from global !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
