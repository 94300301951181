.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: var(--default_increment);
  width: auto;
  height: auto;
  border-radius: calc(4 * var(--border-radius-sm));
  overflow: hidden;
}

/* TODO: this is just placeholder for hero component */
.topComponent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 156px;
  gap: 8px;
  width: 100%;
  height: 228px;
  background: linear-gradient(90deg, #131a46 0%, rgba(19, 26, 70, 0) 100%),
    url('https://www.agro-chemistry.com/wp-content/uploads/2018/04/Maisveld.jpg');
  border-radius: 4px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: white;
  font-size: 22px;
}
