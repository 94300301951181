.renderContent a {
  text-decoration: underline;
}
.renderContent a:hover {
  text-decoration: none;
}

.renderContent h1 {
  /* headlineS */
  font-size: var(--font-size-xxlarge);
  font-weight: bold;
  line-height: var(--line-height-large);
  color: var(--ion-color-primary-darker);

  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

.renderContent h2 {
  /* headlineS */
  font-size: var(--font-size-xlarge);
  font-weight: bold;
  line-height: var(--line-height-medium);
  color: var(--ion-color-primary-darker);

  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

.renderContent h3 {
  /* headlineXS */
  font-size: var(--font-size-large);
  font-weight: bold;
  line-height: var(--line-height-small);
  color: var(--ion-color-primary-darker);

  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

.renderContent h4 {
  /* bodyMBold */
  font-size: var(--font-size-default);
  font-weight: bold;
  line-height: var(--line-height-xsmall);
  color: var(--ion-color-primary-darker);

  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

.renderContent h5 {
  /* bodySBold */
  font-size: var(--font-size-small);
  font-weight: bold;
  line-height: var(--line-height-xsmall);

  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

.renderContent h6 {
  /* bodyMDefault */
  font-size: var(--font-size-default);
  font-weight: normal;
  line-height: var(--line-height-xsmall);
  color: var(--ion-color-primary-darker);

  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

.renderContent hr {
  background-color: var(--ion-color-primary-lighter);
}

.renderContent blockquote {
  border-left: 2px solid var(--ion-color-primary-darker);
  padding-left: calc(2 * var(--default_increment));
  margin: 0;
}

.renderContent code {
  background-color: var(--ion-color-primary-lightest);
}

.renderContent p {
  margin-bottom: var(--default_increment);
}

.renderContent table {
  width: 100%;
  margin-bottom: var(--default_increment);
}
.renderContent th,
.renderContent td {
  padding: var(--default_increment);
  border: 1px solid var(--ion-color-primary-lighter);
  border-collapse: collapse;
}

.renderContent thead {
  background-color: var(--ion-color-primary-lightest);
}

.renderContent > *:first-child {
  margin-top: 0;
}
