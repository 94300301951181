.totalTitle {
  font-family: var(--ion-font-family);
  font-size: calc(2 * var(--default_increment));
  font-weight: 700;
  line-height: 1.25rem;
  color: var(--ion-color-primary);
}

.paymentIcon,
.paymentIcon svg {
  width: 1.15rem;
  height: 1.15rem;
}
