.titleContainer {
  margin-bottom: 16px;
}

.mainColumn {
  row-gap: unset;
}

.sideColumn {
  row-gap: calc(var(--default_increment));
}
