.rowSeparator {
  grid-column: span 4;
  border-top: solid 0.063rem var(--ion-color-primary-lighter);
}

.rowSeparator:first-of-type {
  border-top: none;
  margin-top: unset;
}

.area .value {
  display: none;
  padding-left: 0.625rem;
  text-decoration-line: underline;
}

.area {
  margin-left: 1.125rem;
  display: flex;
  align-items: center;
}

.area:nth-of-type(2),
.area:nth-of-type(3) {
  margin-top: -0.5rem;
}

.area:last-of-type,
.area:nth-last-of-type(2) {
  margin-bottom: -0.5rem;
}

.area a {
  display: flex;
  align-items: center;
}

.area svg {
  width: 1.5rem;
  height: 1.5rem;
}

.contactTitle.contactTitle {
  margin: 0;
  font-size: var(--font-size-small);
  line-height: 1.5rem;
  font-weight: 700;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.contactTitle:first-of-type {
  border-top: none;
  padding-top: unset;
}

.contactTitle:last-of-type {
  padding-bottom: unset;
}
.contactTitle + .area {
  margin-left: 0;
}

@media screen and (min-width: 960px) {
  .rowSeparator {
    grid-column: span 3;
    margin-top: 0.5rem;
  }

  .contactTitle:last-of-type {
    padding-bottom: 0.5rem;
  }

  .area:nth-of-type(2),
  .area:nth-of-type(3) {
    margin-top: unset;
  }

  .area:last-of-type,
  .area:nth-last-of-type(2) {
    margin-bottom: unset;
  }

  .area .value {
    display: block;
  }

  ion-card-content .contactTitle {
    grid-column: span 3;
  }
}
