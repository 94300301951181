.actions.highlightedActions {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin: calc(2 * var(--default_increment));
}
.actions.highlightedActions .button {
  display: block;
  margin: 0;
}
.actions.highlightedActions .button .buttonContent {
  justify-content: center;
}

.actions.highlightedActions .button:last-of-type:not(:only-of-type) {
  margin-top: var(--default_increment);
}

.skeletonItems {
  display: flex;
  flex-direction: column;
}

.qrButtonWrapper {
  flex: 1 1;
}

.qrContainer div > ion-button {
  display: flex;
  flex: 1 1;
}
