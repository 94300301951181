.geographyWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.iconTextWrapper,
.geoLabelWrapper {
  display: inline-flex;
  align-items: center;
  gap: var(--default_increment);
  composes: clickable from global;
}

.tooltip {
  display: inline-flex;
}

.iconTextWrapper span .tooltip,
.iconTextWrapper svg {
  width: 1.25rem;
  height: 1.25rem;
}

.geoLabelTooltipWrapper {
  color: var(--ion-color-primary-darker);
}

.geoValueWrapper {
  color: var(--ion-color-primary);
}

.geoValue {
  font-size: var(--font-size-small);
  letter-spacing: 0.02em;
  line-height: 1.714;
  text-decoration-line: underline;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 9rem;
}

.modalButton {
  width: 100%;
}

.modalContent {
  max-height: 60vh;
}

:global(#geography_modal .modal-wrapper) {
  margin-top: initial;
}

.labelWrapper {
  display: flex;
  align-items: center;
  gap: var(--default_increment);
}

.flags {
  min-width: 1.625rem;
  height: 1.25rem;
  border-radius: calc(var(--default_increment) / 2);
}
