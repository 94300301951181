:global(.expandable .modal-wrapper) {
  position: absolute;
  top: 3.65rem;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

@media (max-width: 769px) {
  :global(.expandable .modal-wrapper .ion-page) {
    height: 92vh;
  }
  .filler {
    height: auto;
    flex-grow: 1;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  :global(.expandable .modal-wrapper) {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    bottom: auto;
    margin: 0;
  }
}

@media (min-width: 1024px) {
  :global(.expandable .modal-wrapper) {
    width: 64rem !important;
    top: 1.5rem;
    bottom: auto;
    left: auto;
    right: auto;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.line {
  margin: 0;
  border-top: 0.067rem solid var(--ion-color-primary-lighter);
}

.header {
  display: flex;
  flex-direction: row;
  gap: var(--default_increment);
  align-items: center;
  margin-left: 0.5rem;
}

.closeButton {
  padding-top: 0rem;
  margin: 0.5rem;
  border: 0;
}

.closeButton:hover {
  border: 0;
}

.closeButton:hover:not(:active),
.closeButton:focus:not(:active) {
  border: 0;
}

.content {
  overflow: auto;
}

.srOnly {
  position: absolute;
  left: -999em;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@media (min-width: 769px) and (max-width: 1023px) {
  .content {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
  }
}

@media (min-width: 1024px) {
  .content {
    top: 0;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
  }
}
