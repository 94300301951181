.SideBarSiteWrapper {
  padding: calc(var(--default_increment) * 2);
  line-height: calc(var(--default_increment) * 2.5);
  border-bottom: 1px solid var(--ion-color-primary);
}

.SideBarSite {
  padding: var(--default_increment);
  display: flex;
  cursor: pointer;
  line-height: calc(var(--default_increment) * 2.5);
  color: var(--ion-color-primary-contrast);
}

.SideBarSite:hover {
  background: var(--ion-color-primary);
  border-radius: calc(var(--default_increment) * 0.5);
}

.iconWrapper {
  display: flex;
  align-items: flex-start;
}

.icon {
  display: flex;
  width: calc(var(--default_increment) * 2.5);
  height: calc(var(--default_increment) * 2.5);
  margin-right: var(--default_increment);
  color: var(--ion-color-primary-contrast);
}

.title {
  font-weight: 700;
  margin-bottom: calc(var(--default_increment) * 0.5);
}

.siteName span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.customTooltip {
  margin-left: 15px;
}

.active {
  background: var(--ion-color-secondary);
  border-radius: calc(var(--default_increment) * 0.5);
}
