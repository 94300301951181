.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--column-spacing);
  flex-direction: column;
  text-align: center;
}

.title {
  text-align: center;
}

.emailInput {
  margin-top: var(--default_increment);
}

.actionButton {
  width: 100%;
}
