.mainContainer {
  display: flex;
  flex-direction: column;

  width: 30rem;
  row-gap: calc(2 * var(--default_increment));
}

.image {
  left: 0;
}

.sideImage {
  border-radius: var(--default_increment);
  background: var(--ion-color-primary-lightest);
}

.border {
  border-bottom: unset;
}

@media screen and (max-width: 767.99px) {
  .mainColumn {
    flex: unset;
  }

  .mainContainer {
    width: 100%;
  }
}
