.status_icon_checkedin {
  color: var(--ion-color-danger);
}

.status_icon_checkedout {
  color: var(--ion-color-success);
}

.status_icon_confirmed {
  color: var(--ion-color-success);
}
