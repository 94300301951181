.applicationInfo {
  font-size: var(--font-size-small);
}

.modalWidth {
  min-width: 20rem;
}

.modalButton {
  width: 100%;
}
