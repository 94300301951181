.hint {
  border-top: unset;
  min-height: unset;
  font-size: var(--font-size-small);
}

@media screen and (max-width: 960px) {
  .calendarButton ion-button {
    width: 100%;
  }
}
