p.fieldsDisclaimer {
  color: var(--ion-color-primary);
  font-size: var(--font-size-xsmall);
  padding-top: var(--default_increment);
}

.sideImage {
  border-radius: var(--default_increment);
  background: var(--ion-color-primary-ultralight);
}

.mainContainer {
  --spacing: calc(var(--default_increment));
  --column-width: 21.5rem;

  display: flex;
  flex-direction: column;
  row-gap: var(--spacing);
  width: 100%;
  max-width: var(--column-width);
  text-align: left;
}

@media screen and (max-width: 767.99px) {
  .mainColumn {
    flex: unset;
  }
}
