.wrapper {
  display: inline-block;
}

.text {
  line-height: 1.5rem;
  vertical-align: top;
}

.showLess {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.wrapper ion-button {
  height: 1.5rem;
}

.wrapper ion-button::part(native) {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.textButton {
  line-height: 1.5rem;
}
