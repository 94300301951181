.marker {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.markerIcon {
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.063rem solid var(--ion-color-primary-darker);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;
}

.markerIconComplete {
  border: 0;
  background: var(--icon-color-green-medium);
  color: white;
}

.line {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  margin: var(--default_increment) 0;
  height: calc(3 * var(--default_increment));
}

.line div {
  height: 100%;
  border-left: 1px dashed #131a46;
}

.markerName {
  margin-left: 0.75rem;
}

.markerDate {
  margin-left: var(--default_increment);
}
