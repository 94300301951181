.promotionTile h6,
.promotionTile p,
.promotionTile button {
  color: var(--ion-color-primary-contrast);
}

.promotionButton {
  border-color: var(--ion-color-tertiary);
  border-width: 2px;
}

.promotionButton:hover {
  border-color: var(--ion-color-tertiary);
  border-width: 2px;
  color: var(--ion-color-primary-contrast);
}

.promotionActive {
  background: var(--icon-color-green-light);
  color: var(--ion-color-success);
  fill: var(--ion-color-success);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: var(--default_increment);
  border-radius: var(--border-radius-md);
}

.promotionActive {
  font-weight: bold;
  font-size: var(--font-size-small);
  line-height: 24px;
}
