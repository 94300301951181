@value sideBarSection from '../../molecules/SideBarSection/SideBarSection.module.css';

.mobileWrapper {
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  left: -100%;
  top: 0;
  background-color: var(--ion-color-primary-darker);
  width: calc(100vw - 68px);
  transition: left 0.5s ease-in-out;
}

.mobileWrapper .sideBarSection {
  border-bottom: 1px solid var(--ion-color-primary);
}

.sideBarSection:last-child {
  border-bottom: none;
}

.blurred {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.32);
}

.open {
  left: 0;
}
