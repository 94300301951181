@value column as importedColumn from '../Column/Column.module.css';

.container {
  --column-spacing: calc(2 * var(--default_increment));
  --column-width: 21.5rem;

  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--column-spacing);
  max-width: 87rem;
}

.centered {
  display: flex;
  height: 100%;
}

.centered .importedColumn {
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .container {
    --column-spacing: calc(3 * var(--default_increment));
  }
}

@media screen and (min-width: 1024px) {
  .container {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }

  .centered {
    align-items: unset;
    max-width: unset;
  }

  .centered .importedColumn {
    max-width: 50%;
  }
}

@media screen and (min-width: 1664px) {
  .centered {
    align-items: unset;
    max-width: calc(100% - 48px);
  }
}
