.mapAddressContainer {
  --containerHeight: 13.5rem;
  height: var(--containerHeight);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mapAddressContainer > img {
  object-fit: cover;
  height: var(--containerHeight);
  border-top-left-radius: var(--default_increment);
  border-bottom-left-radius: var(--default_increment);
}

.mapAddressContainer > div {
  height: var(--containerHeight);
  padding: calc(2 * var(--default_increment));
}

.geoCard ion-card :global(.card-content-md),
.geoCard ion-card :global(.card-content-ios) {
  padding-top: unset;
  padding-bottom: unset;
  padding-inline-start: unset;
  padding-inline-end: unset;
}
