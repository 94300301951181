/* TODO change Card component to new approach injecting this styling as className from ActionsBar directly */
@value cardContent from '../../molecules/Card/Card.module.css';
.actionsBar .cardContent {
  display: grid;
  gap: var(--default_increment);
}

.actionsBar {
  --outer-offset: var(--column-spacing);
  z-index: 101;
}

.static {
  position: static;
  order: 1;
}

/* Sticky position everywhere when actionsBar is in main column */
.actionsBar.inMainColumn:not(.static) {
  position: sticky;
  bottom: var(--outer-offset);
  order: 1;
}

/* Sticky position should be applied only in "portrait" mode */
@media screen and (max-width: 1023.99px) and (min-height: 400px) {
  .actionsBar:not(.static) {
    position: sticky;
    bottom: var(--outer-offset);
    order: 1;
  }
}

@media screen and (min-width: 1024px) {
  .inMainColumn {
    width: var(--column-width);
    margin-left: auto;
    margin-right: auto;
  }
}
