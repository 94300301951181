.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageContentBody {
  font-weight: 700;
  font-size: var(--font-size-small);
  text-align: center;
}
