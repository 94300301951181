@value SideBarItemWrapper from '../../atoms/SideBarItem/SideBarItem.module.css';

.sideBarSection {
  padding: calc(var(--default_increment) * 2);
  line-height: calc(var(--default_increment) * 2.5);
  color: var(--ion-color-primary-contrast);
  box-sizing: border-box;
}

.scrollable {
  overflow-y: auto;
  padding-top: 0;
}

.scrollable.collapsed {
  padding-top: calc(var(--default_increment) * 2);
  padding-right: calc(var(--default_increment) * 1.5);
}

.sideBarSection .SideBarItemWrapper {
  margin-bottom: 4px;
}

.SideBarItemWrapper:last-child {
  margin-bottom: 0;
}

.title {
  margin-top: calc(var(--default_increment) * 2);
  margin-bottom: calc(var(--default_increment) * 1.5);
}
.scrollTitleClear {
  height: calc(var(--default_increment) * 5.5);
  width: 100%;
}

.scrollTitleBck {
  padding-top: calc(var(--default_increment) * 2);
  padding-bottom: calc(var(--default_increment) * 1.5);
  height: calc(var(--default_increment) * 5.5);
  width: 90%;
  position: absolute;
  background-color: var(--ion-color-primary-darker);
}

.scrollable .title {
  position: fixed;
}
