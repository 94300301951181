.header {
  display: grid;
  grid-template-columns: 7.5rem 1fr;
  gap: var(--column-spacing);
}

.header .image > img,
.header .image > svg {
  display: block;
}

@media screen and (min-width: 1024px), (max-width: 767.99px) and (min-height: 640px) {
  .header {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .header {
    grid-template-columns: 12rem 1fr;
  }
}

.headerResponsive {
  display: flex;
  flex-direction: row;
}

.headerResponsive .imageWrapper {
  margin-right: 16px;
}

.headerResponsive .image,
.headerResponsive .image img {
  width: 120px;
  height: 120px;
}

@media (min-height: 640px) {
  .headerResponsive {
    flex-direction: column;
  }

  .headerResponsive .imageWrapper {
    margin-right: 0px;
  }

  .headerResponsive .image,
  .headerResponsive .image img {
    width: 100%;
    height: 32vh;
  }
}

@media (min-width: 768px) {
  .headerResponsive {
    flex-direction: row;
  }

  .headerResponsive .imageWrapper {
    margin-right: 16px;
  }

  .headerResponsive .image,
  .headerResponsive .image img {
    width: 192px;
    height: 192px;
  }
}

@media (min-width: 1024px) {
  .headerResponsive .image,
  .headerResponsive .image img {
    width: 100%;
    height: 32vh;
  }

  .headerResponsive .imageWrapper {
    width: 100%;
    margin-right: 0px;
  }
}
