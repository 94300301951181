.layoutWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  width: var(--default-content-size);
  align-self: flex-start;
  color: var(--ion-color-primary-darker);
  composes: bodySDefault from global;
}

.column > div:not(:last-child) {
  margin-bottom: calc(2 * var(--default_increment));
}

.imageColumn {
  width: var(--default-content-size);
  height: 15rem;
  margin: calc(2 * var(--default_increment)) 0rem calc(2 * var(--default_increment))
    calc(3 * var(--default_increment));
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .layoutWrapper {
    flex-direction: column-reverse;
  }

  .column {
    margin: calc(2 * var(--default_increment)) 0rem 0rem;
    align-self: unset;
  }

  .imageColumn {
    position: static;
    height: 12.5rem;
    width: 12.5rem;
    margin: calc(2 * var(--default_increment)) 0rem;
  }
}
