.inputField {
  --placeholder-color: var(--ion-color-primary-light);
  --placeholder-opacity: 1;
  color: var(--ion-color-primary-darker);
  height: 2.5rem;
  border-radius: var(--border-radius);
  padding-left: var(--padding-start);
  padding-right: var(--padding-end);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  display: inline-block;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  border: 0;
  outline: none;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-appearance: none;
  appearance: none;
  --padding-top: var(--default_increment);
  --padding-end: var(--default_increment);
  --padding-bottom: var(--default_increment);
  --padding-start: var(--default_increment);
  z-index: 1;
  --border-radius: var(--default_increment);
}

.inputStyling {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.inputWithBackground {
  background: var(--default-background-color);
  border: 0.067rem solid var(--ion-color-primary-darker);
  border-radius: var(--border-radius-md);
}

.clearIcon {
  z-index: 1;
  position: relative;
  color: var(--ion-color-primary);
  border-radius: var(--border-radius-md);
}

.inputClearIcon {
  padding-left: calc(var(--default_increment) * 0.75);
  padding-right: calc(var(--default_increment) * 0.75);
}

.inputClearIconWithBackground {
  line-height: 0;
  border-radius: var(--border-radius-md);
}

.inputClearIconWithoutBackground {
  background-color: var(--ion-color-white);
}

.errorMsg {
  border: 0.067rem solid var(--ion-color-danger);
}

.validMsg {
  border: 0.067rem solid var(--ion-color-success);
}

.focused {
  box-shadow: 0 0 0.267rem var(--ion-color-secondary);
}

.disabled {
  background: var(--ion-color-primary-ultralight);
}

.input:global(.has-focus) + span.inputStyling {
  box-shadow: 0rem 0rem 0.267rem var(--ion-color-secondary);
}

.validInputWrapper .input + span.inputStyling {
  border: 0.067rem solid var(--ion-color-success);
}

.validInputWrapper .input:global(.has-focus) + span.inputStyling {
  box-shadow: 0rem 0rem 0.267rem var(--ion-color-success);
}

.errorInputWrapper .input + span.inputStyling {
  border: 0.067rem solid var(--ion-color-danger);
}

.errorInputWrapper .input:global(.has-focus) + span.inputStyling {
  box-shadow: 0rem 0rem 0.267rem var(--ion-color-danger-focus);
}

.input input:global(.native-input[disabled].sc-ion-input-md),
.input input:global(.native-input[disabled].sc-ion-input-ios) {
  opacity: 1;
}
