.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: var(--spacing);
  position: relative;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.image {
  position: absolute;
  object-fit: cover;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.image svg {
  position: absolute;
  top: 0;
  right: 0;
}

.image::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(270deg, rgba(19, 26, 70, 0) 0%, var(--ion-color-primary-darker) 100%);
  z-index: 0;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

@media screen and (min-width: 1024px) {
  .header {
    padding-top: calc(2 * var(--spacing));
  }
}
