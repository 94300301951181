.scanLocationCode {
  display: flex;
  flex-direction: column;
  gap: var(--default_increment);
}

.scanLocationCode > * {
  flex: 1;
}

.qrScanButton {
  width: 100%;
}
