/**
 Ionic adds an offset of 290px to the ion-content element when it detects 
 that the keyboard is open, this was causing a conflict with the Dropdown component.
 The Dropdown component uses a property "isSearchable=false" to prevent it from triggering
 the keyboard, but for some reason, in iOS, Ionic was detecting that it was open and adding
 the offset anyway, which caused the ActionBar to move to the middle of the device's screen
 to compensate for the offset.

 To override the rule "!important" is needed.
*/
:global(ion-content) {
  --keyboard-offset: 0 !important;
}

#SideBarSplitPane-main {
  width: 100%;
}

.pageContent {
  padding: calc(2 * var(--default_increment));
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

/* Medium */
@media screen and (min-width: 768px) {
  .pageContent {
    padding: var(--column-spacing);
    /* TODO MW: regression in older layout, might be acceptable for a time being */
    /* padding-bottom: 0; */
  }
}

/* Temporary, needs to be changed to 768px breakpoint when we remove old layout and adjust NavBar behavior */
/* TODO MW: agreed with Remy to change this in old layout to 768px too */
@media screen and (min-width: 720px) {
  .pageContent.withSideBarCollapsed {
    padding-left: calc(var(--sidebar-vertical-width-collapsed) + var(--column-spacing));
    padding-right: var(--column-spacing);
  }
  .pageContent.withSideBarFull {
    padding-left: calc(var(--sidebar-vertical-width-full) + var(--column-spacing));
    padding-right: var(--column-spacing);
  }
}

@media screen and (min-width: 1664px) {
  .pageContent.withSideBarFull {
    padding-left: unset;
    padding-right: unset;
    width: calc(100% - (var(--sidebar-vertical-width-full)));
    margin-left: var(--sidebar-vertical-width-full);
    align-items: center;
  }
}

@media screen and (min-width: 1508px) {
  .pageContent.withSideBarCollapsed {
    padding-left: unset;
    padding-right: unset;
    width: calc(100% - (var(--sidebar-vertical-width-collapsed)));
    margin-left: var(--sidebar-vertical-width-collapsed);
    align-items: center;
  }
}

.skipToContent {
  position: absolute;
  left: 50%;
  padding: var(--default_increment);
  background: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast-text);
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 11;
}

.skipToContent:focus {
  transform: translateY(0%);
}
