.buttonLabel {
  font-size: var(--font-size-small);
  font-weight: bold;
}

.dateTimeInputs {
  display: flex;
  align-items: center;
  gap: calc(var(--default_increment) * 2);
}

.dateTimeInputs > div {
  width: 100%;
}

.actionButton {
  width: 100%;
}
