.notificationWrapper {
  margin-bottom: calc(3 * var(--default_increment));
}

.notificationWrapper h4 {
  font-weight: bold;
}
.warningLabel {
  font-size: var(--font-size-large);
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.2;
  color: var(--ion-color-danger);
}

.nonWarningLabel {
  font-size: var(--font-size-large);
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.2;
  color: var(--ion-color-primary-darker);
}

.modalContent {
  max-height: 60vh;
}

:global(#nutritions_modal .modal-wrapper) {
  margin-top: initial;
}
