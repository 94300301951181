.loginForm {
  z-index: 1;
}

.textWrapper {
  display: flex;
  gap: calc(var(--default_increment) / 2);
}

.connectionMsg {
  color: var(--ion-color-primary-darker);
}

.loginForm p.fieldsDisclaimer {
  color: var(--ion-color-primary);
  font-size: var(--font-size-small);
}

.loginForm p.topBottomMargins {
  margin-top: calc(2 * var(--default_increment));
  margin-bottom: calc(2 * var(--default_increment));
}

.loginForm p.topMargin {
  margin-top: calc(2 * var(--default_increment));
}

.loginForm p.bottomMargin {
  margin-bottom: calc(2 * var(--default_increment));
}

.sideImage {
  border-radius: var(--default_increment);
  background: var(--ion-color-primary-ultralight);
}

.mainContainer {
  --spacing: calc(3 * var(--default_increment));
  --column-width: 21.5rem;

  display: flex;
  flex-direction: column;
  row-gap: var(--spacing);
  width: 100%;
  max-width: var(--column-width);
  text-align: left;
}

@media screen and (max-width: 767.99px) {
  .mainColumn {
    flex: unset;
  }
}

.geographySelector {
  margin-bottom: calc(2 * var(--default_increment));
}
