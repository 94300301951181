.timeInput {
  display: flex;
  flex-direction: column;
}

.input {
  width: 9rem;
  height: 2.25rem;
  background: #f5f6fc;
  border: 0.063rem solid var(--ion-color-primary-darker);
  border-radius: 0.25rem;
  padding: 0.5rem;
  text-align: center;
  color: var(--ion-color-primary-darker);
}

.button {
  color: var(--ion-color-primary-darker);
  margin: 0.25rem 0;
}

.button[disabled] {
  --background: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary-ultralight);
}

.button svg.icon {
  width: 1.25rem;
}
