.wrapper {
  margin-top: var(--default_increment);
}

.error {
  color: var(--ion-color-danger);
}

.success {
  color: var(--ion-color-success);
}

.list {
  margin: 0;
  list-style: none;
  padding: 0;
}

.rule {
  display: flex;
  align-items: center;
  margin-top: var(--default_increment);
}

.icon {
  width: 1.25em;
  height: 1.25em;
  margin-right: var(--default_increment);
  display: block;
}
