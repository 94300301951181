/* Displays */
.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

/* Orders
  To be used mainly for columns grid where 1-12 is standard order
*/
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}

/* Text transforms */
.lowercase {
  text-transform: lowercase;
}

/* Margins */
.mb-S {
  margin-bottom: var(--default_increment);
}

.mb-M {
  margin-bottom: calc(2 * var(--default_increment));
}

.mb-L {
  margin-bottom: calc(3 * var(--default_increment));
}

.mb-XL {
  margin-bottom: calc(4 * var(--default_increment));
}

/* Border-radius */
.br-md {
  border-radius: var(--border-radius-md);
}

/* Other */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.inlineContainer {
  container-type: inline-size;
}

/* Font */
.fw-B {
  font-weight: bold;
}
