.wrapper {
  width: var(--column-width);
  margin: auto;
}

.button {
  width: 100%;
}

@media screen and (max-width: 378px) {
  .wrapper {
    width: auto;
  }
}
