.sliderContainer {
  padding: calc(2 * var(--default_increment));
  width: 100%;
  height: auto;
  background: var(--ion-color-white);
  border-radius: var(--border-radius-md);
  box-shadow: var(--default-shadow);
}

.sliderContainer
  > div
  > div
  :global
  .slick-list
  > .slick-track
  > .slick-slide:not(:only-child)
  > div:last-child:not(:empty):not(:only-child)
  > div
  > div
  > div {
  border-top: 0.063rem solid var(--ion-color-primary-ultralight);
  padding-top: calc(2 * var(--default_increment));
  margin-top: calc(2 * var(--default_increment));
}

.mobileSlider {
  z-index: 2;
  position: sticky;
  bottom: var(--column-spacing);
}

.progressBar > div {
  position: absolute;
  bottom: 0;
  left: 0;
  height: var(--increment);
}

.progressBar > div > div {
  background-color: var(--ion-color-secondary);
  border-bottom-left-radius: var(--border-radius-md);
  transition: linear;
}

.hideMobileSlider,
.hideProgressBar {
  display: none;
}

.closeButton {
  width: 100%;
  margin-top: calc(2 * var(--default_increment));
}

@media screen and (min-width: 1024px) {
  .mobileSlider {
    display: none;
  }
}
