.wrapper {
  background-size: cover;
  background-position: center;
  padding: calc(var(--default_increment) * 2);
  width: 100%;
  border-radius: var(--border-radius-sm);
}

.bgColor {
  background-color: var(--ion-color-primary);
}

.message {
  margin: 0;
  margin-bottom: var(--default_increment);
  font-weight: 100;
  font-size: var(--font-size-default);
  line-height: var(--font-size-default);
  color: var(--default-background-color);
}

.username {
  margin: 0;
  font-weight: 700;
  font-size: var(--font-size-xxlarge);
  line-height: var(--font-size-xxlarge);
  color: var(--default-background-color);
}

@media (min-width: 1024px) {
  .wrapper {
    padding-bottom: calc(var(--default_increment) * 20);
  }
}
