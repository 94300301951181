.tile {
  --default_tile_height: 7rem;
  --tile_border_radius: 8px;
  max-width: 100%;
  margin: 0;
  margin-bottom: var(--default_increment);
  display: flex;
  border-radius: var(--tile_border_radius);
  box-shadow: var(--default-shadow);
  background-color: #ffffff;
  position: relative;
}

.tile div .service_icon .filled,
.tile div:not(:first-child) svg .filled {
  fill: var(--ion-color-primary-darker);
}

.tile .main_wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tile .main_content {
  width: 100%;
  display: flex;
  z-index: 0;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.tile .main_content_default_height,
.tile .main_content_default_height .actions {
  height: var(--default_tile_height);
}

.tile .main_content > * {
  padding-left: calc(var(--default_increment) * 2);
  padding-right: calc(var(--default_increment) * 2);
  width: 100%;
  white-space: normal;
}

.tile .main_content > div {
  width: 100%;
}

.tile .main_content > div.with_corner {
  width: calc(100% - 40px);
}

.tile h3 {
  white-space: normal;
  margin: 0;
}

.tile span.title {
  text-align: start;
}

.tile .comment {
  max-height: 42px;
  line-height: calc(var(--default_increment) * 3);
  color: var(--ion-color-primary);
}

.tile h4.ellipsis,
.tile .comment .ellipsis,
.tile .comment .clamp {
  display: block;
  margin: 0;
  padding: 0;
}

.card-content-md .tile p.ellipsis,
.card-content-md .tile p.clamp {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.tile .addendum {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: var(--default_increment);
  margin-bottom: 0;
  line-height: calc(var(--default_increment) * 3);
}

.tile .addendum .addendumText {
  margin: 0;
}

.twoLinesEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: calc(1.5em * 2); /* line height x 2 lines clamp */
}

.clampedTitle {
  position: relative;
}

.tile .corner_action {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 2.5rem;
  min-width: 2.75rem;
  color: var(--ion-color-tertiary-contrast);
  overflow: visible;
  align-items: center;
  justify-content: center;
  border-radius: var(--tile_border_radius);
  z-index: 1;
}

.tile .corner_action svg {
  height: 1.25rem;
  width: 1.25rem;
}

.tile .corner_action svg .filled {
  fill: var(--ion-color-tertiary-contrast);
}

.tile .corner {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 2.5rem;
  min-width: 2.75rem;
  background-color: var(--ion-color-tertiary);
  border-radius: var(--tile_border_radius);
  z-index: -1;
}

.tile .corner_inactive {
  background-color: var(--ion-color-medium);
}

.tile .actions {
  display: flex;
  flex: 0 content;
  align-items: center;
  justify-content: center;
  margin-right: var(--default_increment);
  cursor: pointer;
}

.tile .actions > * {
  margin: var(--default_increment);
}

.tile .action {
  text-align: center;
  color: var(--ion-color-primary-darker);
}

.tile .action_element {
  display: flex;
  flex-direction: column;
}

.tile .action_element > * {
  margin: auto;
}

.tile .buttonsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--default_increment);
  margin: 0 var(--default_increment) 0 0;
  width: 7.5rem;
  height: 7.5rem;
}

.tile .buttonsWrapper .smallNotification {
  cursor: default;
  padding: var(--default_increment) calc(var(--increment) * 3);
}

.tile .buttonsWrapper .smallNotification div {
  margin-bottom: 0;
}

.tile .buttonsWrapper .smallNotification span {
  display: flex;
}

.tile .tile_banner {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  fill: var(--ion-color-primary-darker);
  stroke-width: 0;
  border-radius: 0;
  background-color: transparent;
  z-index: 1;
}

.tile .tile_banner_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  fill: var(--ion-color-primary-darker);
}

.tile .tile_banner_background_tertiary {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  fill: var(--ion-color-tertiary);
  color: var(--ion-color-primary-darker);
}

.bannerIcon {
  width: 1rem;
  height: 1rem;
  z-index: 1;
  position: absolute;
  left: 0.5rem;
  top: 0.625rem;
  display: block;
  font-size: var(--font-size-default);
}

.tile .tile_banner_background_danger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  fill: var(--ion-color-danger);
}

.tile .tile_banner_star {
  position: absolute;
  top: 0.625rem;
  left: 10%;
  min-width: 0px !important;
  width: 30% !important;
}

.tile .tile_banner_star .filled {
  fill: var(--ion-color-primary-contrast);
}

.tile .secondary .tile_banner_background {
  fill: var(--ion-color-secondary) !important;
}

/*default tile*/
.tile_default {
  align-items: center;
  margin: unset;
}

.tile_default .img_container {
  height: var(--default_tile_height);
  width: var(--default_tile_height);
  border-radius: var(--tile_border_radius);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.tile_default .img_container > div,
.tile_default .img_container > i {
  flex: 0 content;
  height: var(--default_tile_height);
  width: var(--default_tile_height);
  border-radius: var(--tile_border_radius);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-primary-darker);
  font-size: calc(var(--default_tile_height) * 0.4);
}

.tile_default .img_container .tile_banner {
  max-width: 50%;
  max-height: 50%;
}

/*large tile*/
.tile_large {
  height: auto;
  flex-wrap: wrap;
}

.tile_large .main_content {
  display: flex;
  align-items: center;
}

.img_container {
  position: relative;
  overflow: hidden;
  border-radius: var(--tile_border_radius);
  cursor: pointer;
}

.img_container img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center;
  display: block;
}

.img_container > svg {
  width: 100%;
}

.tile_large .img_container {
  width: 100%;
  height: 0px;
  padding-bottom: 100%;
}

.tile_large .img_container i {
  flex: 0 content;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  border-radius: var(--tile_border_radius);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-primary-darker);
  font-size: calc(var(--default_tile_height) / 2);
}

.tile_large .img_container .tile_banner {
  max-width: 25%;
  max-height: 25%;
}

/*toast look*/
.tile_look_toast {
  background-color: var(--ion-color-secondary);
}

.tile_look_toast * {
  color: var(--ion-color-secondary-contrast) !important;
}

.tile_look_toast .main_content {
  height: auto;
}

.tile_look_toast svg * {
  stroke: var(--ion-color-secondary-contrast);
}

.tile_look_toast svg .filled {
  fill: var(--ion-color-secondary-contrast);
}

.tile .actions svg {
  height: 24px;
  width: 24px;
  min-width: 24px;
}

.tile .centered_title_y {
  display: flex;
  align-items: center;
}

.tile .centered_title_y h3 {
  margin-top: 10px;
}

.tile_clickable {
  cursor: pointer;
}
.tile_clickable .img_container {
  cursor: pointer;
}

.tile .main_wrapper.longTextVisibleWrapper .main_content_default_height {
  height: unset;
  min-height: var(--default_tile_height);
}

.tile .main_wrapper .main_content_default_height.longTextVisibleContent {
  padding-top: calc(2 * var(--default_increment));
  padding-bottom: calc(2 * var(--default_increment));
}
