.notificationTilesWrapper {
  display: flex;
  flex-direction: column;
  width: 22.5rem;
}

.notificationItemsWrapper {
  padding: calc(2 * var(--default_increment));
  padding-bottom: 0;
}

.notificationItemWrapper {
  margin-bottom: calc(2 * var(--default_increment));
}

.popoverFooter {
  padding: calc(2 * var(--default_increment));
  border-top: 1px solid var(--ion-color-primary-ultralight);
  display: flex;
  justify-content: center;
  margin-bottom: var(--ion-safe-area-bottom);
}

.seeAllNotifButton {
  width: 100%;
}
