.quantityWrapper {
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.quantityWrapperBig {
  height: 2.75rem;
}

.quantityWrapperInline {
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.button {
  --padding-bottom: 1rem;
  --padding-top: 1rem;

  height: 100%;
  max-width: 2.75rem;
  min-height: 0;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--ion-color-primary-lighter);
  transition: box-shadow 0.2s ease;
}

.quantityWrapper .quantityValue {
  color: var(--ion-color-primary);

  width: 1.5rem;
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.greyOut {
  --background: var(--ion-color-primary-lightest);
}
