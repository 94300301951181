.searchContainer {
  padding-top: calc(2 * var(--default_increment));
  padding-bottom: var(--default_increment);
  padding-left: calc(var(--default_increment) - 0.2rem);
  padding-right: var(--default_increment);
}

.searchFilterWrapper {
  display: flex;
  gap: calc(3 * var(--default_increment));
}

@media screen and (max-width: 960px) {
  .searchFilterWrapper {
    flex-wrap: wrap;
    gap: calc(2 * var(--default_increment));
  }
}

.searchAndFilter > div:first-child:not(:only-child) {
  padding-bottom: calc(2 * var(--default_increment));
}

.onlyFilters {
  display: flex;
}

.hasVisibleFilter {
  gap: var(--default_increment);
}

.listTitle {
  margin-top: 0;
  margin-bottom: calc(2 * var(--default_increment));
}

.listNoResult {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
