.optionsFetchingPlaceholder {
  text-align: left;
}

.container {
  display: flex;
  flex-flow: row wrap;
  gap: calc(2 * var(--default_increment));
}

.dropdown {
  flex-basis: 100%;
}

.inputWrapper {
  margin-top: calc(2 * var(--default_increment));
}

.auditDetailsCard {
  position: relative;
}

.skeletonRow:not(:last-child) {
  margin-bottom: calc(3 * var(--default_increment));
}

.skeletonInput > * {
  height: 2.5rem;
}

@media (min-width: 540px) {
  .dropdown {
    flex-basis: calc(50% - var(--default_increment));
  }
}
