.dateRange {
  margin: auto;
  background-color: var(--ion-color-primary-contrast);
}

@media (min-width: 769px) {
  .dateRange {
    width: calc(var(--default-content-size) * 2);
  }

  .dateRange :global(.react-datepicker__day--outside-month) {
    background: transparent !important;
  }
  .dateRange :global(.react-datepicker__day--outside-month) span {
    background: transparent !important;
  }
}

@media (max-width: 768px) {
  .dateRange {
    flex: 1 0 100%;
    width: var(--default-content-size);
  }
}

.modalContent {
  display: flex;
  justify-content: center;
  padding: calc(2 * var(--default_increment));
}

.dateRange :global(.react-datepicker) {
  display: flex;
  border: 2px solid var(--ion-color-primary-lightest);
}

.dateRange :global(.react-datepicker__aria-live) {
  display: none;
}

.dateRange :global(.react-datepicker__month-container) {
  flex: 1 0 50%;
}

.dateRange :global(.react-datepicker__header.react-datepicker__header--custom) {
  background: var(--ion-color-primary-lightest);
  padding: calc(var(--increment) * 3);
  display: flex;
  flex-direction: column;
  gap: calc(var(--increment) * 5);
}

.defaultDates {
  display: flex;
  column-gap: calc(var(--default_increment) * 2);
  padding-bottom: calc(var(--default_increment) * 2);
}

@media (max-width: 768px) {
  .defaultDates ion-button {
    flex: 1 0 40%;
  }
}

.headerCustom__navigation {
  background: var(--ion-color-primary-lightest);
  height: calc(var(--default_increment) * 4);
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;
  justify-content: space-between;
  align-items: center;
}

.headerCustom__previousLabel,
.headerCustom__nextLabel {
  display: flex;
  color: var(--ion-color-primary-darker);
  height: calc(var(--default_increment) * 3);
  align-items: center;
  min-width: calc(var(--default_increment) * 5);
}

.headerCustom__previousLabel svg,
.headerCustom__nextLabel svg {
  color: var(--ion-color-primary-darker);
}

.headerCustom__previousLabel button,
.headerCustom__nextLabel button {
  background: none;
}

.headerCustom__activeMonth {
  display: flex;
  border-radius: var(--increment);
  border: 2px solid var(--ion-color-primary);
}

.headerCustom__activeMonth span {
  margin: 0 auto;
  padding: var(--default_increment) calc(var(--increment) * 3);
  font-family: var(--ion-font-family);
  font-weight: 700;
  font-style: normal;
  line-height: var(--line-height-small);
  color: var(--ion-color-primary);
}

.dateRange :global(.react-datepicker-popper) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dateRange :global(.react-datepicker__day-names) {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  line-height: calc(var(--increment) * 5);
  justify-content: space-around;
}

.dateRange :global(.react-datepicker__day-name) {
  border-radius: 100px;
  background-color: var(--ion-color-primary-lightest);
  font-weight: bold;
  color: var(--ion-color-primary-darker);
}

.dateRange :global(.react-datepicker__month) {
  display: flex;
  padding: calc(var(--increment) * 3);
  flex-direction: column;
  gap: var(--default_increment);
}

.dateRange :global(.react-datepicker__week) {
  display: flex;
  flex-direction: row;
  gap: 1px;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 100%;
}

.dateRange :global(.react-datepicker__day) {
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  height: calc(var(--default_increment) * 5);
  flex: 1 0 0;
  color: var(--ion-color-primary-darker);
}

.dateRange :global(.react-datepicker__day--today) span {
  border-radius: 100px;
  background-color: var(--ion-color-primary-lightest);
  height: calc(var(--default_increment) * 5);
  width: calc(var(--default_increment) * 5);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dateRange :global(.react-datepicker__day--outside-month) {
  font-weight: normal;
}

.dateRange :global(.react-datepicker__day--selecting-range-start) span {
  background-color: var(--ion-color-tertiary);
  display: flex;
  border-radius: 100px;
  height: calc(var(--default_increment) * 5);
  width: calc(var(--default_increment) * 5);
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dateRange :global(.react-datepicker__day--range-start.react-datepicker__day--in-range) {
  border-radius: 100px 0 0 100px;
  background: linear-gradient(90deg, transparent 50%, var(--ion-color-primary-ultralight) 0);
}

.dateRange :global(.react-datepicker__day--range-end.react-datepicker__day--in-range) {
  border-radius: 0 100px 100px 0;
  background: linear-gradient(90deg, var(--ion-color-primary-ultralight), 50%, transparent 50%);
}
.dateRange :global(.react-datepicker__day--range-end.react-datepicker__day--in-range)::before,
.dateRange :global(.react-datepicker__day--range-start.react-datepicker__day--in-range)::before {
  border-radius: 50%;
  bottom: 0;
  content: '';
  height: inherit;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.dateRange
  :global(.react-datepicker__day--keyboard-selected.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range) {
  background: var(--ion-color-white);
}

.dateRange :global(.react-datepicker__day--range-start.react-datepicker__day--in-range) span,
.dateRange :global(.react-datepicker__day--range-end.react-datepicker__day--in-range) span {
  display: flex;
  border-radius: 100px;
  background-color: var(--ion-color-tertiary);
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dateRange :global(.react-datepicker__day--in-range) {
  background-color: var(--ion-color-primary-ultralight);
}
.dateRange :global(.react-datepicker__day--in-range) span {
  background-color: var(--ion-color-primary-ultralight);
}

.validateButton {
  width: 100%;
}
