.titleWrapper {
  padding-bottom: 1rem;
}

.wrapper {
  border-bottom: solid 1px var(--ion-color-primary-ultralight);
  padding-top: 0.75rem;
}

.wrapper > div > div > div {
  padding-bottom: 1rem !important;
  margin: unset;
}

.wrapper:last-child {
  border-bottom: unset;
}
