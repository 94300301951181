.wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  --line-height: 0.3em;
  --border-width: 0.06666666667em;
  composes: captionDefault from global;
}

.item::before {
  position: absolute;
  content: '';
  background-color: var(--ion-color-primary-lighter);
  width: 100%;
  height: var(--line-height);
  top: 50%;
  transform: translateY(-50%);
  left: -50%;
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
}

.title {
  display: none;
}

.counter {
  float: left;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  font-weight: bold;
  color: var(--ion-color-primary);
  background: var(--ion-color-primary-lighter);
  border: var(--border-width) solid var(--ion-color-primary-lighter);
}

.active .counter {
  border-color: var(--ion-color-primary);
}

.completed .counter {
  color: var(--ion-color-secondary-contrast);
  background: var(--ion-color-secondary);
  border-color: var(--ion-color-secondary);
}

.item.completed::before,
.item.active::before {
  background: var(--ion-color-secondary);
}

.item:first-child::before,
.item:last-child::after {
  content: none;
}

@media screen and (min-width: 1024px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
    --item-spacing: 3rem;
  }

  .wrapper .item::before {
    width: var(--line-height);
    height: var(--item-spacing);
    top: -100%;
    left: calc(1em - var(--line-height) / 2);
  }

  .wrapper .item {
    align-items: center;
    margin-bottom: var(--item-spacing);
  }

  .wrapper .item:last-of-type {
    margin-bottom: 0px;
  }

  .wrapper .title {
    display: block;
    margin-left: 2.5rem;
  }

  .horizontalWrapper {
    flex-direction: unset;
    align-items: unset;
    --item-spacing: unset;
  }

  .horizontalWrapper .item::before {
    width: 100%;
    height: var(--line-height);
    top: 50%;
    left: -50%;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding: 0;
  }
}
