.mapAddressContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 13.5rem;
}

.map > div > div {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.image {
  height: 16rem;
}

.image > svg {
  width: 100%;
  height: 100%;
  padding: 0.8rem 0 0.8rem 0;
}

.mapAddressContainer > .address {
  padding: calc(2 * var(--default_increment));
}

.geoCard ion-card :global(.card-content-md),
.geoCard ion-card :global(.card-content-ios) {
  padding-top: unset;
  padding-bottom: unset;
  padding-inline-start: unset;
  padding-inline-end: unset;
}

.italicText {
  font-style: italic;
  color: var(--ion-color-primary);
}
@media (min-height: 640px) {
  .titleSide {
    margin-top: calc(var(--default_increment) * 2);
  }
}
@media (min-width: 768px) {
  .titleSide {
    margin-top: 0px;
  }
}
.titleMain {
  display: none;
}
@media (min-width: 1024px) {
  .titleSide {
    display: none;
  }
  .titleMain {
    display: block;
  }
}
