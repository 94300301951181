@media (min-height: 640px) {
  .titleSide {
    margin-top: 16px;
  }
}

@media (min-width: 768px) {
  .titleSide {
    margin-top: 0px;
  }
}

.titleMain {
  display: none;
}

@media (min-width: 1024px) {
  .titleSide {
    display: none;
  }

  .titleMain {
    display: block;
  }
}
