:global(.reactSelect__control) {
  background-color: var(--ion-color-primary-lightest);
  border-color: var(--ion-color-primary-darker);
  border-radius: var(--border-radius-md);
  height: 2.5rem;
}
:global(.reactSelect__control--is-focused) {
  box-shadow: 0rem 0rem 0.25rem var(--ion-color-secondary);
}

:global(.reactSelect__single-value) {
  color: var(--ion-color-primary-darker);
}
:global(.reactSelect__placeholder) {
  color: var(--ion-color-primary-light);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

:global(.reactSelect__menu) {
  border-radius: var(--border-radius-md);
  border: none;
  box-shadow: var(--default-shadow);
  color: var(--ion-color-primary-darker);
  z-index: 102;
  padding: 0.25rem 0.25rem 0.5rem 0.5rem;
}

:global(.reactSelect__menu-list > div:nth-of-type(even)) {
  background-color: var(--ion-color-primary-lightest);
}
:global(.reactSelect__menu-list > div.reactSelect__option--is-focused),
:global(.reactSelect__option--is-focused) {
  background-color: var(--ion-color-primary-ultralight);
}

:global(.reactSelect__option--is-selected) {
  background-color: var(--ion-color-primary-ultralight);
  color: var(--ion-color-primary-darker);
  font-weight: bold;
}

:global(.reactSelect__option--is-suppressed) {
  color: var(--ion-color-primary-light) !important;
}

/*
* TODO define proper color
*/
:global(.reactSelect__option--is-disabled) {
  color: var(--ion-color-primary-lighter);
}

:global(.reactSelect__control--is-disabled) {
  background-color: var(--ion-color-primary-ultralight);
}

.dropdownIcon {
  width: 1.4rem;
}

.selectInput {
  position: relative;
}

.errorWrapper {
  color: var(--ion-color-danger);
}

.errorWrapper :global(.reactSelect__control) {
  border: 0.067rem solid var(--ion-color-danger);
}

.errorIcon {
  pointer-events: none;
  width: 1.25rem;
  height: 1.25rem;
  z-index: 1;
  margin-top: 0.625rem;
  margin-right: var(--default_increment);
  position: absolute;
  top: 0;
  right: calc(var(--default_increment) * 4);
}

.message {
  margin-top: var(--default_increment);
  display: inline-block;
  composes: bodySDefault from global;
}
