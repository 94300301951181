.option {
  --icon-size: calc(var(--increment) * 8);
  padding: 0;
  background-color: inherit;
  margin: var(--increment);
  border-radius: var(--border-radius-sm);
  height: var(--icon-size);
  width: var(--icon-size);
}

.option svg {
  width: 75%;
  height: 75%;
}
