.synchronisedStatusIcon {
  display: flex;
  align-items: center;
  width: calc(var(--default_increment) * 1.5);
  height: calc(var(--default_increment) * 1.5);
  margin-right: calc(var(--default_increment) * 0.25);
  border-radius: 100%;
}

.statusSynchronised {
  background-color: var(--icon-color-green-medium);
}

.statusUnsynchronised {
  background-color: var(--ion-color-state-red-dark);
}
