.attachments > a > span:first-of-type {
  flex-direction: column;
}

.errorTextWrapper {
  display: flex;
}

.tryAgain {
  min-width: 4rem;
  text-decoration-line: underline;
}

.errorToastBody > div:first-of-type {
  align-items: unset;
}
