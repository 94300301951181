.wrapper {
  overflow: hidden;
  position: relative;
}

.button {
  width: 100%;
  padding: 0.5rem;
  min-height: 2.5rem;
  border: 1px solid var(--ion-color-tertiary-contrast);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--default_increment);
  background-color: var(--ion-color-primary-lightest);
  color: var(--ion-color-primary-darker);
  font-size: var(--font-size-small);
  z-index: -1;
}

.invisibleInput {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}

.label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.icon {
  min-width: 20;
}

.modalContent {
  display: flex;
  justify-content: center;
}
