.chatMessage {
  width: calc(100% - var(--default_increment) * 3);
  clear: both;
  padding: calc(var(--default_increment) * 2);
  margin: var(--default_increment) 0;
  border-radius: var(--default_increment);
  box-shadow: none;
}

.chatMessage ion-card-content {
  padding: var(--default_increment) 0;
}

.fromUserChatMessage {
  float: right;
  --background: var(--ion-color-primary-darker);
  --color: var(--ion-color-primary-contrast);
}

.fromUserChatMessage ion-card-title {
  color: var(--ion-color-primary-contrast);
}

.fromUserChatMessage ion-card-subtitle {
  color: var(--ion-color-primary-contrast);
}

.toUserChatMessage {
  float: left;
  --color: var(--ion-color-primary-darker);
  --background: var(--ion-color-primary-contrast);
  border: 1px solid var(--ion-color-primary-lighter);
  box-sizing: border-box;
}

.toUserChatMessage ion-card-title {
  color: var(--ion-color-primary-darker);
}

.toUserChatMessage ion-card-subtitle {
  color: var(--ion-color-primary-darker);
}

.toUserChatMessage ion-card-content,
.fromUserChatMessage ion-card-content {
  line-height: calc(var(--default_increment) * 3);
}

.actionsContainer {
  margin-top: calc(var(--default_increment) * 2);
}
