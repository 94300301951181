.titleBarLink {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: transparent 2px solid;
  font-weight: 700;
  padding: calc(1.5 * var(--default_increment));
  font-size: 0.875rem;
}

.titleBarLink:hover {
  --background: var(--ion-color-primary-ultralight);
  background: var(--ion-color-primary-ultralight);
  border-radius: 10px;
}

.active.titleBarLink:hover {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.active {
  border-radius: 1px;
  border-bottom: var(--ion-color-primary-darker) 2px solid;
}

.titleBarLink > * {
  display: flex;
  align-items: center;
}

.iconOnTheLeft {
  margin-right: var(--default_increment);
}

.icnWrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.titleBarIconTag {
  position: absolute;
  top: -0.5rem;
  right: 0;
  min-width: 1.25rem;
  padding: 0 0.375rem;
  height: 1.25rem;
  border-radius: 0.625rem;
  background-color: #de3e00;
  color: var(--ion-color-primary-contrast);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: var(--font-size-small);
}

.widgetButton {
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .titleBarIconTag {
    right: -0.4rem;
  }
}
@media screen and (max-width: 720px) {
  .titleBarLink {
    padding: var(--default_increment);
  }
}
