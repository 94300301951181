.facilityProgressWrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--default_increment);
}

.progressWrapper {
  display: flex;
  gap: var(--default_increment);
}

.rewardWrapper {
  display: flex;
}

.progressWidth {
  width: 6.25rem;
}

.progressWrapper > span > span {
  gap: var(--increment);
}

@media screen and (min-width: 768px) {
  .facilityProgressWrapper {
    flex-direction: row;
    justify-content: space-between;
    gap: unset;
  }

  .progressWrapper {
    gap: calc(3 * var(--default_increment));
  }
}
