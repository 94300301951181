.container {
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.content {
  display: flex;
  gap: var(--default_increment);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: calc(2 * var(--default_increment));
}

.title {
  font-weight: 700;
  color: var(--ion-color-primary-darker);
  text-align: justify;
  text-align-last: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
}

.message {
  color: var(--ion-color-primary-darker);
  text-align: justify;
  text-align-last: center;
}

@media (max-width: 960px) {
  .message {
    max-height: 80vh;
    overflow-y: scroll;
  }
}

.inputs {
  display: flex;
  gap: var(--default_increment);
  flex-direction: column;
  width: 100%;
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  border-top: 0.5px solid var(--ion-color-primary-light);
}

.buttons > div {
  display: grid;
}

.buttons > div:not(:last-child) {
  border-right: 0.5px solid var(--ion-color-primary-light);
}

.buttons ion-button {
  text-decoration: initial;
}

.backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

@media (min-width: 540px) {
  .container {
    border-radius: var(--border-radius-md);
    top: 50%;
    left: 50%;
    bottom: initial;
    right: initial;
    transform: translate(-50%, -50%);
  }
}
