:global(#ActionButtonsExternalContainer) {
  max-width: var(--central-content-max-width-in-px);
  margin: 0 auto;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-primary-contrast);
}

:global(#ActionButtonsContainer) {
  max-width: 368px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  /*hack in order to show button shadow when Layout component is used*/
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

:global(#ActionButtonsContainer) div:not(:first-child) ion-button {
  margin-top: var(--default_increment);
}

:global(#ActionsBarToolbar) ion-button {
  margin-inline: 0;
  display: block;
  width: calc(100% - 10px); /*hack in order to show button shadow when Layout component is used*/
}

:global(#ActionsBarToolbar) {
  --background: var(--ion-color-white);
}

:global(#ActionsBarTopContent) {
  width: 100%;
  background-color: var(--default-background-color);
  padding: var(--default_increment);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--default_increment);
}

:global(#ActionsBarTopContent) > ion-item {
  max-width: var(--central-content-max-width-in-px);
}

.ActionBar_ButtonContainer {
  width: 100%;
  position: relative;
}

.ActionBar_ButtonContainer * {
  z-index: 0;
}

.ActionBar_ButtonLeft,
.ActionBar_ButtonRight {
  position: absolute;
  top: 0px;
  margin: 12px;
  color: #ffffff;
  z-index: 1;
}

.ActionBar_ButtonLeft {
  left: 0px;
}

.ActionBar_ButtonRight {
  right: 0px;
}
