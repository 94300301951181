.cartDate {
  margin-top: var(--default_increment);
}

.cartDateWrapper {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-weight: 600;
  margin: 0;
}

.pickupForm > div + div {
  margin-top: calc(2 * var(--default_increment));
}

.buttonLabel {
  display: flex;
  align-items: center;
  gap: var(--default_increment);
}

.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(3 * var(--default_increment));
  width: var(--column-width);
}

.container > div {
  width: 100%;
}

.text {
  margin-top: var(--default_increment);
  margin-bottom: var(--column-spacing);
  color: var(--ion-color-primary-darker);
}

.termsOfSalesLabel {
  display: inline;
}

.termOfSaleLabel {
  margin-left: var(--default_increment);
}

.termOfSaleLabel a {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.modalButton {
  width: 100%;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

.sectionTitleWrapper {
  display: flex;
  align-items: baseline;
}

.sectionTitleWrapper p {
  font-size: var(--font-size-small);
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1.5rem;
}

.borderOfFulfillments {
  margin-left: calc(-2 * var(--default_increment));
  margin-right: calc(-2 * var(--default_increment));
  border-top: 0.067rem solid var(--ion-color-primary-lighter);
}

.requiredStyle {
  color: var(--ion-color-secondary);
  margin-top: var(--default_increment);
}

.withShortMargin {
  margin: 0rem;
}

.locationAndTimeSelectsWrapper {
  margin-top: calc(2 * var(--default_increment));
  display: flex;
  gap: var(--default_increment);
}

.formSelectWrapper {
  flex-grow: 1;
  width: 50%;
}

.actionBar {
  display: flex;
  flex-direction: column;
  gap: var(--default_increment);
}

.paymentModalityTotal {
  font-weight: 600;
}
.paymentLabel {
  font-weight: 400;
}

.notificationWrapper {
  margin-top: 1rem;
}

.actionBarInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
/*todoAndy check design*/
.noMargin {
  margin-top: 0;
  margin-bottom: 0;
}

.stampsLabel {
  font-weight: bold;
  font-size: var(--font-size-small);
  color: var(--ion-color-primary-darker);
}
