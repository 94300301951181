.Homepage_Titlebar {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  align-items: center;
  composes: bodySBold from global;
}

.Homepage_Titlebar span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: calc(2.5 * var(--default_increment));
  padding: calc(var(--default_increment) / 4) calc(1.5 * var(--default_increment));
}

@media screen and (max-width: 720px) {
  .Homepage_Titlebar span {
    margin: calc(1 * var(--default_increment));
    padding: unset;
  }
}
@media screen and (max-width: 440px) {
  .Homepage_Titlebar span {
    font-size: 1rem;
  }
}

.TitleBar_TextContainer {
  display: flex;
  justify-content: center;
}

.TitleBar_TextContainerWithBackButton a {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.TitleBar_MenuButton {
  color: var(--ion-color-primary-contrast);
  margin-right: 10px;
  margin-top: 2px;
}

@media screen and (min-width: 720px) {
  .withSideBarCollapsed {
    padding-left: var(--sidebar-vertical-width-collapsed);
  }
  .withSideBarFull {
    padding-left: var(--sidebar-vertical-width-full);
  }
}

@media screen and (max-width: 320px) {
  .Homepage_Titlebar {
    visibility: hidden;
    display: none;
  }
}
