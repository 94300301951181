@value sideBarSection from '../../molecules/SideBarSection/SideBarSection.module.css';

.sideBarWrapper {
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  background-color: var(--ion-color-primary-darker);
}

.sideBarWrapper .sideBarSection {
  border-bottom: 1px solid var(--ion-color-primary);
}

.sideBarSection:last-child {
  border-bottom: none;
}

.full {
  width: 14rem;
}

.collapsed {
  width: 4.25rem;
}
