.request_status {
  text-transform: capitalize;
}

.request_status_pending {
  color: var(--ion-color-warning);
}

.request_status_open {
  color: var(--ion-color-primary);
}

.request_status_refused {
  color: var(--ion-color-danger);
}

.request_status_closed {
  color: var(--ion-color-success);
}

.statusIcon svg {
  width: 1.1rem;
  margin-right: calc(var(--default_increment) / 2);
}
