.text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.text ion-button {
  height: auto;
  min-height: 1.5rem;
}

.text ion-button span {
  line-height: 1.5;
}

.text ion-button::part(native) {
  padding-right: 4px;
  padding-left: 4px;
}

.notYetSelected {
  margin-left: var(--default_increment);
  position: relative;
  width: 4.5rem;
  height: 1.5rem;
}

.notYetSelected:after {
  content: '';
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='1'><rect width='7' height='1' fill='%232A295C'/></svg>");
  background-repeat: repeat-x;
  position: absolute;
  width: 100%;
  height: 0.05rem;
  bottom: 0.1875rem;
  word-wrap: break-word;
}

.optionsToSelect {
  margin-top: calc(2 * var(--default_increment));
  display: flex;
  flex-wrap: wrap;
  gap: var(--default_increment);
}
