.headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headline.withoutLabel {
  justify-content: flex-end;
}

.label {
  composes: bodySBold from global;
  display: flex;
  align-items: center;
  color: var(--ion-color-primary-darker);
}

.counter {
  composes: captionDefault from global;
  color: var(--ion-color-primary-light);
  display: block;
}

ion-textarea.textarea {
  --background: var(--ion-color-primary-lightest);
  border: 0.067rem solid var(--ion-color-white-contrast);
  border-radius: var(--border-radius-md);
  --border-radius: var(--border-radius-md);
  margin-top: 0rem;
  --padding-start: var(--default_increment);
  --padding-end: var(--default_increment);
  --padding-top: var(--default_increment);
  --padding-bottom: var(--default_increment);
  z-index: unset;
  --color: var(--ion-color-primary-darker);
  display: flex;
}

:global(.textarea-wrapper) {
  width: 100%;
}

.textarea div textarea {
  min-height: 5rem;
  --placeholder-color: var(--ion-color-primary-light);
}

.textarea div textarea:focus {
  box-shadow: 0rem 0rem 0.267rem var(--ion-color-secondary);
}

.isValid .textarea {
  border: 0.067rem solid var(--ion-color-success);
  --background: var(--ion-color-success-light);
}

.isValid .textarea div textarea:focus {
  box-shadow: unset;
}

.isInvalid .textarea {
  border: 0.067rem solid var(--ion-color-danger);
}

.isInvalid .textarea div textarea:focus {
  box-shadow: unset;
}

.textarea div textarea:global(.native-textarea):disabled {
  background: var(--ion-color-primary-ultralight);
  opacity: 1;
}

.msg {
  composes: bodySDefault from global;
  display: inline-block;
  color: var(--ion-color-primary);
}

.isInvalid .msg,
.isInvalid .counter {
  color: var(--ion-color-danger);
}

ion-textarea + p.msg {
  margin-top: calc(var(--default_increment));
}

.textareaIcon {
  width: 1.25rem;
  height: 1.25rem;
  z-index: 1;
  position: relative;
  margin-top: var(--default_increment);
  margin-right: var(--default_increment);
}
