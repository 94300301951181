.wrapper {
  width: fit-content;
  display: flex;
  background-color: var(--ion-color-primary-contrast);
  border: 0.125rem solid var(--ion-color-primary-ultralight);
  padding: 0.75rem;
}

.divider {
  width: 1.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-primary-darker);
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}
