.wrapper {
  display: grid;
  width: 100%;
  gap: var(--default_increment);
}

.contentColumn {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
}

.contentRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: var(--default_increment);
}

.contentRow .labelWrapper {
  width: auto;
}

.item .contentRow {
  max-height: 1rem;
}

.ionItem {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --border-width: 0px;
  --inner-border-width: 0px;
  --min-height: 100%;
  height: 100%;
  border-radius: var(--increment);
}

.item {
  --color: var(--ion-color-black);
  --background: var(--ion-color-grey-ultralight);
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  flex: 0 1 100%;
  width: 100%;
  height: 100%;
  max-height: 4rem;
  --background-hover: var(--ion-color-grey-lighter);
  --background-hover-opacity: 1;
  border-radius: var(--increment);
  --background-focused: var(--ion-color-grey-lighter);
  --background-focused-opacity: 1;
}

.focused {
  outline: 2px solid var(--ion-color-white);
  box-shadow: 0px 0px 8px 0px var(--ion-color-tertiary);
  transition: box-shadow 0.2s ease;
  border-radius: var(--increment);
}

.isSelected {
  --background: var(--ion-color-tertiary);
  font-weight: 600;
  --color: var(--ion-color-tertiary-contrast-text);
  --background-hover: var(--ion-color-tertiary-light);
  --background-hover-opacity: 1;
  --background-focused: var(--ion-color-tertiary-light);
  --background-focused-opacity: 1;
}

.iconWrapper {
  display: flex;
  flex: 0 0 1.4rem;
  justify-content: center;
  width: 100%;
}

.iconWrapper svg {
  width: 1.5rem;
  height: 1.5rem;
}

.isSelected .iconWrapper svg {
  color: var(--ion-color-tertiary-contrast-text);
}

.labelWrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: calc(3 * var(--default_increment));
}

.labelWrapperNotBold {
  font-weight: 300;
}

.buttonWrapper .item {
  --background: transparent;
  border: 2px solid var(--ion-color-primary);
}

.buttonContainer {
  overflow: hidden;
}
