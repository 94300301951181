.mapSwitchIcon {
  stroke: var(--ion-color-primary-darker);
}

:global(.ion-activated) .mapSwitchIcon {
  stroke: var(--ion-color-primary-contrast);
}

.map {
  min-height: 17.13rem;
  display: grid;
}

.errorMessage {
  max-width: 21.12rem;
}

.distance {
  font-weight: bold;
}

.toggleView {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--default_increment);
}

.actionButton {
  width: 100%;
}

.noResultWrapper {
  text-align: center;
  border: 2px dashed var(--ion-color-primary-lighter);
  border-radius: calc(0.5 * var(--default_increment));
  padding: calc(3 * var(--default_increment));
}

p {
  margin: unset;
}

.locationOffWrapper {
  width: calc(4 * var(--default_increment));
  height: calc(4 * var(--default_increment));
  margin-bottom: var(--default_increment);
}

.grayDivider {
  margin: calc(1.5 * var(--default_increment)) 0;
  border-bottom: 1px solid var(--ion-color-primary-lighter);
}

.flagCountryWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: calc(0.5 * var(--default_increment));
  gap: calc(1.5 * var(--default_increment));
}

@media screen and (min-width: 768px) {
  .toggleView {
    display: flex;
    margin-bottom: calc(-1 * var(--default_increment));
  }
}
