.info {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-small);
  line-height: 24px;
}

.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.text {
  margin-top: 12px;
  margin-bottom: 24px;
}
