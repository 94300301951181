.descriptionContainer {
  white-space: pre-wrap;
}

.eventLink {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.seeAllDatesCard {
  cursor: pointer;
}

.calendarModalButton {
  width: 100%;
}

.allDatesListItemTitle {
  display: inline;
  text-align: left;
}

.nextDateListItemTitleWrapper {
  display: flex;
  flex-direction: column;
}

.nextDateListItemTitle {
  display: block;
  text-align: left;
}

.timeSeparator {
  font-weight: 400;
}

.markdownWrapper {
  margin: var(--default_increment) 0;
}

@media screen and (max-width: 400px) {
  .allDatesListItemTitle {
    display: block;
    max-width: 13rem;
  }

  .nextDateListItemTitle {
    max-width: 13rem;
  }
}

@media (min-height: 640px) {
  .titleSide {
    margin-top: 16px;
  }
}

@media (min-width: 768px) {
  .titleSide {
    margin-top: 0px;
  }
}

.titleMain {
  display: none;
}

@media (min-width: 1024px) {
  .titleSide {
    display: none;
  }

  .titleMain {
    display: block;
  }
}
