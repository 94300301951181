.bulletPointList {
  margin: 0;
}

.actionsWrapper {
  background-color: var(--ion-color-primary-contrast);
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: var(--default_increment);
  z-index: 100;
}
