.tilesList {
  padding: 0;
}

.tilesList .sectionTop {
  margin: var(--default_increment);
  margin: 1em 0;
  padding: 0;
}

.tilesList .sectionTop *,
.sectionBottom * {
  color: var(--ion-color-primary-darker);
}

.emptyBottomSpace{
  margin-bottom: 350px;
}

.tilesListSection {
  container-type: inline-size;
}

/* TODO adjust for expandable sections, need to add expanded/collapsed class to be able to target via css */
.tilesListSection:not(:nth-last-child(1)) {
  margin-bottom: calc(var(--default_increment) * 1.5);
}

.tilesList .tile:nth-last-child(1) {
  margin-bottom: 0;
}

.tilesListSectionHeader {
  padding: 0;
  border-bottom: solid 1px var(--ion-color-primary-darker);
  margin-bottom: calc(var(--default_increment) * 2);
  --background: #f9faff;
}

.subSection {
  color: var(--ion-color-primary-light);
  border-bottom: solid 1px var(--ion-color-primary-lighter);
}

.subSection .subSectionTitle {
  color: var(--ion-color-primary-light);
}

.title {
  margin: var(--default_increment) 0;
  color: var(--ion-color-primary-darker);
  display: flex;
  align-items: center;
}

.sectionTitleIcon {
  margin-right: var(--default_increment);
  display: flex;
  align-items: center;
}

.tilesListSectionHeader .expandCollapseIcon {
  width: 24px;
}

.tilesListSectionHeader .expandCollapseIcon .filled {
  fill: var(--ion-color-primary-darker);
}

.tilesListSectionHeader .expandCollapseIcon path {
  stroke: var(--ion-color-primary-darker);
}

.tilesList ion-card:not(:first-child) {
  margin-top: var(--default_increment);
}

.tilesWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--default_increment) var(--column-spacing);
}

.tileWrapper {
  display: grid;
}

.section {
  color: var(--ion-color-primary-darker);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: calc(1.5 * var(--default_increment));
}

.newSubSection {
  color: var(--ion-color-primary-light);
  font-size: 0.875rem;
  font-weight: 700;
  margin-top: calc(0.5 * var(--default_increment));
}

@media screen and (min-width: 1024px) and (max-width: 1235px), screen and (max-width: 767.99px) {
  .tilesWrapper {
    grid-template-columns: 1fr;
  }
}

@container (min-width: 720px) {
  .newSubSection {
    margin-top: calc(1.5 * var(--default_increment));
  }
}

@supports not (container-type: inline-size) {
  @media (min-width: 720px) {
    .newSubSection {
      margin-top: calc(1.5 * var(--default_increment));
    }
  }
}
