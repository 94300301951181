.widget {
  --spacing: calc(2 * var(--default_increment));
  --title-color: var(--ion-color-primary-contrast);
  --border-radius: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  box-shadow: var(--default-shadow);
  background-color: var(--ion-color-primary-contrast);
}
