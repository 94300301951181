.tileContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid var(--ion-color-primary-ultralight);
  border-radius: var(--border-radius-md);
}

.contentContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.active {
  border: 2px solid var(--ion-color-tertiary);
}
