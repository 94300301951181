#BackButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: calc(1.5 * var(--default_increment));
  margin-right: calc(2 * var(--default_increment));
}

#BackButton svg {
  color: var(--ion-color-primary-darker);
  fill: var(--ion-color-primary-darker);
}

@media screen and (max-width: 720px) {
  #BackButton {
    padding: calc(1 * var(--default_increment));
  }
}