.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: relative;
}

.itemLink {
  border-bottom: 0.067rem solid var(--ion-color-primary-lighter);
  list-style: none;
}

.item {
  padding-top: var(--default_increment);
  padding-bottom: var(--default_increment);
  border-bottom: 0.067rem solid var(--ion-color-primary-lighter);
  display: flex;
  align-items: center;
  color: var(--ion-color-primary-darker);
}

.itemContainer {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  color: var(--ion-color-primary-darker);
}

.aLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--ion-color-primary-darker);
  padding-top: var(--default_increment);
  padding-bottom: var(--default_increment);
}

.item:first-child,
.itemLink:first-child .aLink {
  padding-top: 0;
}

.item:last-child,
.itemLink:last-child .aLink {
  border-bottom: none;
  padding-bottom: 0;
}

.itemLink:last-child {
  border-bottom: none;
}

.itemLabelWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.labelValueWrapper {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: space-between;
  overflow: hidden;
}

.labelWrapper,
.valueWrapper {
  max-width: 100%;
  padding-top: calc(var(--default_increment) / 2);
  padding-bottom: calc(var(--default_increment) / 2);
  display: block;
}

.labelWrapper {
  display: flex;
}

.labelTextWrapper {
  font-weight: bold;
}

.labelTextWrapper span {
  font-weight: normal;
}

.item span.disabled .labelValueWrapper {
  opacity: 0.5;
}

.iconsWrapper {
  margin-left: var(--default_increment);
}

.icon {
  height: calc(var(--default_increment) * 3);
  width: calc(var(--default_increment) * 3);
  margin-right: var(--default_increment);
  float: left;
}

p.label,
.label {
  max-width: 100%;
  font-size: var(--font-size-small);
  font-weight: 700;
  line-height: 1.5rem;
  padding-right: var(--default_increment);
}

.label::first-letter {
  text-transform: uppercase;
}

.control {
  padding-left: var(--default_increment);
  margin-left: auto;
}

.value {
  composes: bodySDefault from global;
  max-width: 100%;
}

.wrapLine {
  flex-direction: column;
}

.actionIcon {
  composes: clickable from global;
  margin-left: var(--default_increment);
  color: var(--ion-color-primary-darker);
  display: flex;
}

.actionIcon .icon {
  margin-right: 0;
}

.externalLinkIcon {
  color: var(--ion-color-primary);
}

@container (min-width: 720px) {
  .twoColumns {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: calc(var(--default_increment) * 4);
  }

  .twoColumns:before {
    width: 0.067rem;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    content: '';
    background-color: var(--ion-color-primary-lighter);
  }

  .twoColumns .item {
    padding: var(--default_increment) 0;
  }

  .twoColumns .item:last-child,
  .twoColumns .item:nth-last-child(2):nth-child(odd) {
    border-bottom: none;
    padding-bottom: 0;
  }
}

@supports not (container-type: inline-size) {
  @media screen and (min-width: 768px) and (max-width: 1023px), screen and (min-width: 1153px) {
    .twoColumns {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: calc(var(--default_increment) * 4);
    }

    .twoColumns:before {
      width: 0.067rem;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      content: '';
      background-color: var(--ion-color-primary-lighter);
    }

    .twoColumns .item {
      padding: var(--default_increment) 0;
    }

    .twoColumns .item:last-child,
    .twoColumns .item:nth-last-child(2):nth-child(odd) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
