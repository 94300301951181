.clampedTitle {
  position: relative;
}

.container {
  container-type: inline-size;
  --border-color: var(--ion-color-primary-ultralight);
  --background: transparent;
  --background-color: #ffffff;
  --background-color-secondary: var(--default-background-color);
  --text-color: var(--ion-color-primary-darker);
  --text-color-secondary: var(--ion-color-primary);
  --text-color-tertiary: var(--ion-color-primary-light);
  --box-shadow-color: #7c8adc3d;
  --highlight-color: var(--ion-color-tertiary);
  --spacing-smallest: 0.25rem;
  --spacing-small: 0.5rem;
  --spacing-normal: 0.75rem;
  --spacing-large: 1rem;
  --spacing-largest: 1.5rem;
  --border-radius: 0.25rem;
  --border-radius-big: 0.5rem;
  --col-action-width: 2.5rem;
  --col-image-width: 4.5rem;
  --col-image-big-width: 8.5rem;
  --col-details-width: 15rem;
  --border-size: 1px;
}

.container:has(.buttonPopoverActive) {
  z-index: 1;
}

.dark {
  --border-color: var(--ion-color-primary-darker);
  --background: var(--ion-color-primary-darker);
  --background-color: var(--ion-color-primary-darker);
  --background-color-secondary: var(--ion-color-primary-darker);
  --text-color: var(--ion-color-primary-lightest);
  --text-color-secondary: var(--ion-color-primary-ultralight);
  --text-color-tertiary: var(--ion-color-primary-ultralight);
  --box-shadow-color: #7c8adc3d;
  --highlight-color: var(--ion-color-tertiary);
  --ion-color-primary: var(--ion-color-primary-lightest);
  --ion-color-dark-contrast: var(--ion-color-primary);
  --ion-color-primary-contrast: var(--ion-color-primary);
  color: var(--ion-color-primary);
}

.containerDark .component:not(.variantBig) {
  padding-bottom: 0px;
}

.component {
  position: relative;
  background-color: var(--background);
  border-bottom: var(--border-size) solid transparent;
  border-image: linear-gradient(90deg, transparent 92px, var(--border-color) 92px);
  border-image-slice: 1;
  border-radius: var(--border-radius-big);
  padding: var(--spacing-smallest);
  padding-bottom: var(--spacing-large);
  display: grid;
  grid-template-columns: var(--col-image-width) minmax(0, 1fr) var(--col-action-width);
  grid-template-areas: 'image details actions';
  gap: var(--spacing-large);
  margin: var(--border-size);
}

.actionsWithLabelComponent {
  grid-template-columns: var(--col-image-width) minmax(0, 1fr) 7rem;
  grid-template-areas: 'image details actions';
}

.withoutImage {
  border-bottom: var(--border-size) solid var(--ion-color-primary-ultralight);
  border-image: unset;
}

.component.withoutImage {
  grid-template-columns: minmax(0, 1fr) var(--col-action-width);
  grid-template-areas: 'details actions';
}

.actionsWithLabelComponent.withoutImage {
  grid-template-columns: minmax(0, 1fr) 6rem;
  grid-template-areas: 'details actions';
}

.container:last-of-type > .component:not(.variantBig) {
  border-bottom: 0px;
  padding-bottom: var(--spacing-smallest);
}

.componentWithClick {
  cursor: pointer;
}

.dark > .component:not(.skeleton):hover {
  background-color: var(--box-shadow-color);
}

.component:not(.skeleton):hover {
  background-color: var(--border-color);
}

.corner {
  position: absolute;
  top: calc(-1 * var(--spacing-small));
  left: calc(-1 * var(--spacing-small));
  border: var(--border-size) solid var(--border-color);
  background-color: var(--background-color);
  border-radius: 50%;
  padding: var(--spacing-small);
  width: 2.25rem;
  height: 2.25rem;
}

.corner > div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.corner svg {
  fill: var(--ion-color-primary-darker);
  width: 1.125rem;
  height: 1.125rem;
}

.image {
  grid-area: image;
  padding-top: 100%;
  position: relative;
  height: 0;
}

.imageSkeleton {
  padding-top: 0 !important;
  height: auto !important;
  margin-bottom: -4px !important;
}

.image svg,
.image img {
  border-radius: var(--border-radius);
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.details {
  grid-area: details;
  min-width: 0;
  display: grid;
  gap: var(--spacing-smallest);
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
}

.details > div {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-smallest);
}

.details p {
  margin: 0;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: var(--text-color);
  line-height: 1.2;
  margin: 0;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(8 * var(--default_increment));
}

.description > p {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--text-color-secondary);
  margin-bottom: 0;
}

.oneLine {
  -webkit-line-clamp: 1;
}

.actions {
  grid-area: actions;
  display: grid;
  grid-template-rows: repeat(auto-fill, var(--col-action-width));
  gap: var(--spacing-smallest);
  width: var(--col-action-width);
}

.actionsWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  gap: var(--spacing-smallest);
}

.actionsWithLabel:first-child {
  width: 5.625rem;
}

.actionsWithLabel:nth-child(2) {
  width: 6rem;
}

.actions > div,
.actions :global(.button),
.actions ion-button {
  height: var(--col-action-width);
  width: inherit;
  min-height: auto;
}

.actions ion-button::part(native) {
  height: var(--col-action-width);
}

.stickers {
  display: flex;
  gap: var(--spacing-smallest);
  flex-wrap: wrap;
}

.customContent {
  width: 100%;
  max-width: 107px;
}

.contentContainer {
  display: flex;
  gap: var(--component-spacing);
}

.chip.primary {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

.chip.secondary {
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-secondary-contrast);
}

.chip.success {
  --background: var(--ion-color-success);
  --color: var(--ion-color-success-contrast);
}

.chip.warning {
  --background: var(--ion-color-warning);
  --color: var(--ion-color-warning-contrast);
}

.chip.danger {
  --background: var(--ion-color-danger);
  --color: var(--ion-color-danger-contrast);
}

.chip.dark {
  --background: var(--ion-color-dark);
  --color: var(--ion-color-dark-contrast);
}

.chip.light {
  --background: var(--ion-color-light);
  --color: var(--ion-color-light-contrast);
}

.chip,
.chip > span {
  background-color: var(--background);
  border-radius: var(--spacing-smallest);
  padding: 0 calc(var(--spacing-smallest) / 2);
  display: inline-flex;
  gap: var(--spacing-smallest);
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  min-width: 65px;
  max-width: 180px;
  width: fit-content;
}

.chip > span,
.tag > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 50px;
}

.chip,
.chip label {
  color: var(--color);
  font-size: 0.875rem;
  font-weight: 400;
}

.chip svg {
  width: 1.25rem;
}

.tag.primary {
  --color: var(--ion-color-primary);
}

.tag.secondary {
  --color: var(--ion-color-secondary);
}

.tag.success {
  --color: var(--ion-color-success);
}

.tag.warning {
  --color: var(--ion-color-warning);
}

.tag.danger {
  --color: var(--ion-color-danger);
}

.tag.dark {
  --color: var(--ion-color-dark);
}

.tag.light {
  --color: var(--ion-color-light);
}

.tag,
.tag > span {
  display: inline-flex;
  gap: var(--spacing-smallest);
  min-width: 65px;
  align-items: center;
}

.tag,
.tag label {
  color: var(--color);
  font-size: 0.875rem;
  font-weight: 700;
  align-items: center;
  line-height: 16px;
}

.tag svg {
  width: 1.25rem;
}

.variantBig {
  background-color: var(--background-color);
  border: var(--border-size) solid var(--border-color);
  grid-template-columns: 1fr var(--col-action-width);
  grid-template-areas:
    'image image'
    'details actions';
  padding: var(--spacing-large);
}

.variantBig .image {
  margin: calc(-1 * var(--spacing-small));
  margin-bottom: 0;
  padding-top: calc(100% + calc(2 * var(--spacing-small)));
}

.variantBig.highlight {
  border-color: var(--highlight-color);
  border-width: calc(2 * var(--border-size));
  margin: 0;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-normal);
}

.listContainer.listBig {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-normal);
}

.listContainer .component {
  height: 100%;
}

.buttonPopoverContainer {
  position: relative;
}

.popoverContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--spacing-small);
  background-color: var(--background-color);
  z-index: 1;
  box-shadow: 0px 2px 4px 0px var(--box-shadow-color);
  border-radius: var(--border-radius);
}

.popoverContainerBelow {
  min-width: 248px;
  top: 45px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.popoverContainerBelow .popoverBellowButton > span {
  justify-content: start;
}

:hover .popoverContainer :nth-child(even),
:hover .popoverContainer :nth-child(even) ion-button,
.popoverContainer :nth-child(even),
.popoverContainer :nth-child(even) ion-button {
  --background: var(--background-color-secondary);
  --border-radius: 0;
}

.popoverContainer > div,
.popoverContainer :global(.button),
.popoverContainer ion-button {
  height: 2.5rem;
  width: inherit;
  text-decoration: none;
  min-height: initial;
}

.popoverContainer ion-button::part(native) {
  height: 2.5rem;
  padding: calc(2 * var(--spacing-smallest));
}

.childText {
  display: inline-flex;
  flex-direction: column;
  gap: calc(var(--default_increment) / 2);
  color: var(--text-color-secondary);
  font-size: 1rem;
  font-weight: 700;
}

.strikethroughChildText {
  text-decoration: line-through;
  color: var(--text-color-tertiary);
  font-weight: 400;
}

.variantBig .childText {
  flex-direction: row;
  gap: var(--default_increment);
}

.withChildren {
  grid-template-rows: 1fr max-content;
  grid-template-columns: var(--col-image-width) 1fr;
  grid-template-areas:
    'image details'
    'image children';
}

.variantBig.withChildren {
  grid-template-columns: 1fr;
  grid-template-rows: initial;
  grid-template-areas:
    'image'
    'details'
    'children';
}

.childrenContainer {
  grid-area: children;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--spacing-normal);
}

.childrenContainer.withoutImage {
  grid-column-start: 1;
  border-bottom: unset;
}

.childTextContainer {
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
}

.childrenContentContainer {
  display: flex;
  justify-content: flex-start;
  min-width: 0;
}

.fullHeight {
  height: 100%;
}

.variantBig .childrenContainer.withoutImage {
  grid-column-start: initial;
}

.variantBig .childrenContainer {
  gap: var(--spacing-normal);
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
}

.details.withoutImage {
  grid-column-start: 1;
  border-bottom: unset;
}

.variantBig .details.withoutImage {
  grid-row-start: 1;
}

.details.withoutActions {
  grid-column-end: last-col;
}

.variantBig .actions.withoutImage {
  grid-row-start: 1;
}

.scaleDownLoader {
  transform: scale(0.5);
  transform-origin: center;
}

@container (min-width: 320px) {
  .childrenContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-large);
  }

  .childTextContainer {
    align-self: unset;
  }
}

@supports not (container-type: inline-size) {
  @media (min-width: 400px) {
    .childrenContainer {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-large);
    }

    .childTextContainer {
      align-self: unset;
    }
  }
}

@container (min-width: 720px) {
  .component:not(.variantBig) .details {
    grid-template-columns: minmax(0, var(--col-details-width)) 1fr;
    gap: var(--spacing-largest);
    margin-right: var(--spacing-small);
  }

  .childText {
    flex-direction: row;
    gap: var(--default_increment);
  }

  .withChildren {
    grid-template-columns: var(--col-image-width) 1fr auto;
    grid-template-rows: initial;
    grid-template-areas: 'image details children';
  }

  .childrenContainer {
    gap: var(--spacing-normal);
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
    align-self: end;
  }

  .childrenContainer.withoutImage {
    grid-column-start: initial;
  }

  .childTextContainer {
    justify-content: end;
  }

  .childrenContentContainer {
    justify-content: end;
  }
}

@supports not (container-type: inline-size) {
  @media (min-width: 720px) {
    .component:not(.variantBig) .details {
      grid-area: details;
      min-width: 0;
      display: grid;
      gap: var(--spacing-smallest);
      grid-auto-rows: max-content;
      grid-template-columns: 100%;
    }

    .childText {
      flex-direction: row;
      gap: var(--default_increment);
    }

    .withChildren {
      grid-template-rows: 1fr max-content;
      grid-template-columns: var(--col-image-width) 1fr;
      grid-template-areas:
        'image details'
        'image children';
    }

    .childrenContainer {
      grid-area: children;
      min-width: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-large);
    }

    .childrenContainer.withoutImage {
      grid-column-start: initial;
    }

    .childTextContainer {
      justify-content: end;
    }

    .childrenContentContainer {
      justify-content: end;
    }
  }
}

@container (min-width: 415px) {
  .variantBig {
    grid-template-columns: var(--col-image-big-width) 1fr var(--col-action-width);
    grid-template-areas: 'image details actions';
    padding: var(--spacing-smallest);
    padding-right: var(--spacing-normal);
  }

  .variantBig .details,
  .variantBig .actions,
  .variantBig .childrenContainer {
    padding: var(--spacing-small) 0;
  }

  .variantBig .image {
    margin: 0;
    padding-top: 100%;
  }

  .listContainer.listBig {
    display: flex;
    flex-direction: column;
  }

  .variantBig .childText {
    flex-direction: row;
    gap: var(--default_increment);
  }

  .variantBig.withChildren {
    grid-template-columns: var(--col-image-width) 1fr auto;
    grid-template-rows: initial;
    grid-template-areas: 'image details children';
  }

  .variantBig.withChildren {
    grid-template-columns: var(--col-image-big-width) 1fr auto;
    padding-bottom: var(--spacing-smallest);
  }

  .variantBig .childrenContainer {
    gap: var(--spacing-normal);
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
    align-self: end;
  }

  .variantBig .childrenContainer.withoutImage {
    grid-column-start: initial;
  }

  .variantBig .childrenContainer.withoutImage {
    grid-column-start: initial;
  }

  .variantBig .childTextContainer {
    justify-content: end;
  }

  .variantBig .childrenContentContainer {
    justify-content: end;
  }

  .variantBig .details.withoutImage {
    grid-row-start: initial;
  }
}

@supports not (container-type: inline-size) {
  @media (min-width: 415px) {
    .variantBig {
      grid-template-columns: var(--col-image-big-width) 1fr var(--col-action-width);
      grid-template-areas: 'image details actions';
      padding: var(--spacing-smallest);
      padding-right: var(--spacing-normal);
    }

    .variantBig .details,
    .variantBig .actions,
    .variantBig .childrenContainer {
      padding: var(--spacing-small) 0;
    }

    .variantBig .image {
      margin: 0;
      padding-top: 100%;
    }

    .listContainer.listBig {
      display: flex;
      flex-direction: column;
    }

    .variantBig .childText {
      flex-direction: row;
      gap: var(--default_increment);
    }

    .variantBig.withChildren {
      grid-template-columns: var(--col-image-width) 1fr auto;
      grid-template-rows: initial;
      grid-template-areas: 'image details children';
    }

    .variantBig.withChildren {
      grid-template-columns: var(--col-image-big-width) 1fr auto;
      padding-bottom: var(--spacing-smallest);
    }

    .variantBig .childrenContainer {
      gap: var(--spacing-normal);
      flex-direction: column;
      justify-content: initial;
      align-items: initial;
      align-self: end;
    }

    .variantBig .childrenContainer.withoutImage {
      grid-column-start: initial;
    }

    .variantBig .childrenContainer.withoutImage {
      grid-column-start: initial;
    }

    .variantBig .childTextContainer {
      justify-content: end;
    }

    .variantBig .childrenContentContainer {
      justify-content: end;
    }

    .variantBig .details.withoutImage {
      grid-row-start: initial;
    }
  }
}

@container (min-width: 842px) {
  .listContainer.withTwoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-normal);
  }
}

@supports not (container-type: inline-size) {
  @media (min-width: 842px) {
    .listContainer.withTwoColumns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--spacing-normal);
    }
  }
}
