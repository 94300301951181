.slickList :global .slick-list {
  overflow: hidden;
}

.slickList :global .slick-list .slick-track {
  display: flex;
  align-items: normal;
}

.seeAll {
  position: absolute;
  right: calc(var(--default_increment) * 16);
  margin-left: var(--default_increment);
}

.above {
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--default_increment) * 2);
  justify-content: space-between;
  height: 40px;
  position: relative;
}

.PrevArrowButton {
  position: absolute;
  top: calc(var(--default_increment) * -7); /* 16px bottom margin + button height */
  right: calc(var(--default_increment) * 6); /* button wide + button margin*/
  left: unset;
}

.NextArrowButton {
  position: absolute;
  top: -56px; /* 16px bottom margin + button height */
  right: 0px;
}

.PrevArrowButton svg,
.NextArrowButton svg {
  height: 20px;
  width: 20px;
}

.slickDots {
  position: initial !important;
}

/* Dark mode */

.sliderContainerDark .arrowBtn {
  --ion-color-tertiary: var(--ion-color-primary-contrast);
}

.sliderContainerDark {
  background-color: var(--ion-color-primary-darker);
}

.sliderContainerDark h2 {
  color: var(--ion-color-primary-contrast);
}

.lightDots button::before {
  color: white !important;
}

.lightDots button::before {
  color: white !important;
}

.div {
  display: grid;
}
