.button {
  width: 100% !important;
  padding: 0.625rem 1rem;
  max-height: 2.5rem;
  cursor: pointer;
  border-radius: calc(var(--default_increment) / 2);
}

.button.disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

.primary {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast-text);
}

.primary:hover {
  background: var(--ion-color-tertiary-light);
}

.primary:focus-within:not(:hover),
.primary:active {
  outline: 2px solid var(--ion-color-white);
  box-shadow: 0px 0px 8px 0px var(--ion-color-tertiary);
}

.primary.disabled {
  box-shadow: none;
  outline: none;
  background-color: var(--ion-color-grey-ultralight);
  color: var(--ion-color-grey-darker);
}

.secondary {
  background-color: var(--ion-color-primary-contrast);
  border: 2px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
}

.secondary:hover {
  color: var(--ion-color-secondary);
}

.secondary:hover:not(:active),
.secondary:focus-within:not(:active) {
  border: 2px solid var(--ion-color-secondary);
  color: var(--ion-color-secondary);
}

.secondary:focus-within:not(:hover),
.secondary:active:not(.disabled) {
  outline: 2px solid var(--ion-color-white);
  box-shadow: 0px 0px 8px 0px var(--ion-color-secondary);
}

.secondary.disabled,
.secondary.disabled:hover,
.secondary.disabled:focus-within {
  color: var(--ion-color-grey-darker);
  border: 2px solid var(--ion-color-grey-darker);
}

.tertiary {
  background-color: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
  text-decoration: underline;
}

.tertiary:hover,
.tertiary:focus-within {
  color: var(--ion-color-secondary);
}

.tertiary:focus-within:not(:hover),
.tertiary:active {
  background-color: var(--ion-color-white);
  box-shadow: 0px 0px 8px 0px var(--ion-color-secondary);
}

.tertiary:active {
  color: var(--ion-color-primary);
}

.tertiary.disabled,
.tertiary.disabled:active {
  color: var(--ion-color-grey-darker);
  box-shadow: none;
  background-color: var(--ion-color-primary-contrast);
}

.buttonValue {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.buttonValue > div > div {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.chevronIcon {
  display: flex;
  margin-left: var(--default_increment);
}

.menu {
  min-width: 100%;
  position: absolute;
  margin-top: 0.25rem;
  border-radius: var(--border-radius-md);
  border: none;
  box-shadow: var(--default-shadow);
  color: var(--ion-color-primary-darker);
  z-index: 102;
  background-color: var(--ion-color-primary-contrast);
  padding: var(--default_increment);
}

.optionEven {
  background-color: var(--ion-color-primary-lightest) !important;
}

.value span {
  font-weight: 400;
}
