.wrapper {
  position: relative;
  border: 0;
  padding: 0;
  border-radius: var(--border-radius-md);
}

.wrapper:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--ion-color-primary-lighter);
  transition: box-shadow 0.2s ease;
}

.image {
  border-radius: var(--border-radius-md);
  object-fit: cover;
}

.zoomBtnContentWrapper {
  z-index: 2;
  display: flex;
  padding: 0 16px;
}

.legend {
  opacity: 0.88;
  background-color: var(--ion-color-primary-contrast);
  position: absolute;
  border-top-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
  width: 100%;
  height: 44px;
  z-index: 1;
  bottom: 0;
  left: 0;
}

.zoomBtn {
  color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  position: absolute;
  height: 44px;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.zoomBtn svg {
  margin-right: 12px;
}

.zoomBtnText {
  font-weight: bold;
  text-decoration: underline;
}

.modalContent {
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
